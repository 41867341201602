import { isMobile } from 'src/utils/common';

import Iconify from '../../../components/iconify';
import { PATH_ROOT } from '../../../routes/paths';

// ----------------------------------------------------------------------

const icon = (name: string) => <Iconify icon={`${name}`} width={20} height={20} />;

const navConfig = [
  {
    subheader: 'FCOS',
    isAdmin: false,
    isMobileAccessible: true,
    items: [
      {
        path: PATH_ROOT.fcChart.root,
        title: 'FC차트',
        // icon: icon('uil:grid'),
      },
      {
        path: PATH_ROOT.dashboard.root,
        title: '대시보드',
        // icon: icon('uil:chart'),
      },
      {
        path: PATH_ROOT.project.root,
        title: '프로젝트',
        // icon: icon('ion:list'),
      },
      {
        path: PATH_ROOT.calendar.root,
        title: '캘린더',
        // icon: icon('mdi:calendar-outline'),
      },
      {
        path: PATH_ROOT.crm.root,
        title: '고객관리',
        // icon: icon('mdi:calendar-outline'),
      },
    ],
  },

  {
    subheader: 'MANAGEMENT',
    isAdmin: true,
    isMobileAccessible: false,
    items: [
      {
        title: '사용자관리',
        path: PATH_ROOT.management.user,
        // icon: icon('ph:users-bold'),
      },
      {
        title: '권한설정관리',
        path: PATH_ROOT.management.permission,
        // icon: icon('icon-park-outline:permissions'),
      },
      {
        title: '권한그룹관리',
        path: PATH_ROOT.management.group,
        // icon: icon('tdesign:user-setting'),
      },
      {
        title: '메뉴권한관리',
        path: PATH_ROOT.management.menu,
        // icon: icon('dashicons:welcome-widgets-menus'),
      },
      {
        title: '시스템설정',
        path: PATH_ROOT.management.setting,
        // icon: icon('uil:setting'),
      },
    ],
  },
];

export default navConfig;
