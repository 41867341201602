import { ApiResponse } from 'apisauce';
import { IPaginationSnapshot } from 'src/models/pagination/Pagination';
import { IResponseSnapshot } from 'src/models/response/Response';

import { ICalendar, IProject, IProjectFilter, IProjectSnapshot } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import {
  TDeleteProjectResult,
  TGetCalendarResult,
  TGetProjectResult,
  TGetProjectsResult,
  TPostProjectResult,
  TUpdateProjectResult,
} from './ProjectTypes';

/**
 * # Project Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new ProjectApi();
 *
 * ```
 */
export class ProjectApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(paging: IPaginationSnapshot, params?: IProjectFilter): Promise<TGetProjectsResult> {
    try {
      let url;

      if (params) {
        // @ts-ignore
        const queryParams = new URLSearchParams(params).toString();
        url = `/v1/project?page=${paging.page}&size=${paging.size}&${queryParams}`;
      } else {
        url = `/v1/project?page=${paging.page}&size=${paging.size}`;
      }
      // const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const projects: IProject[] = response.data.data;
      const pagination: IPaginationSnapshot = response.data.pagination;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', projects, pagination, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(projectSid: number): Promise<TGetProjectResult> {
    try {
      const url = `/v1/project/${projectSid}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const project: IProject = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', project, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(newProject: IProjectSnapshot): Promise<TPostProjectResult> {
    try {
      const url = '/v1/project';
      const payload = newProject;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const project: IProject = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', project, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(newProject: IProjectSnapshot): Promise<TUpdateProjectResult> {
    try {
      const url = `/v1/project/${newProject.projectSid}`;
      const payload = newProject;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const project: IProject = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', project, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(projectSid: number): Promise<TDeleteProjectResult> {
    try {
      const url = `/v1/project/${projectSid}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async calendar(params?: IProjectFilter): Promise<TGetCalendarResult> {
    try {
      let url;
      if (params) {
        // @ts-ignore
        const queryParams = new URLSearchParams(params).toString();
        url = `/v1/calendar?${queryParams}`;
      } else {
        url = `/v1/calendar`;
      }
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const data: ICalendar[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
