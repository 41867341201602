import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { HEADER } from 'src/config-global';
import { useStores } from 'src/models';
import { isMobile } from 'src/utils/common';

import Header from './OsfcHeader';

export type OsfcLayoutContext = {
  setCustomLayoutStyle: React.Dispatch<React.SetStateAction<SxProps<Theme> | null>>;
};
// ----------------------------------------------------------------------
export default function OsfcLayout() {
  const theme = useTheme();
  const { commonCodeStore } = useStores();
  const [customLayoutStyle, setCustomLayoutStyle] = useState<SxProps<Theme> | null>(null);
  useEffect(() => {
    commonCodeStore.getsCommon();
    commonCodeStore.getsDynamic();
  }, []);

  return (
    <>
      <Box
        minWidth={isMobile() ? 'auto' : 900}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 1,
        }}
      >
        <Header />
        <Box
          id="osfc-layout"
          component="main"
          sx={{
            flex: 1,
            p: 2,
            pt: {
              xs: HEADER.H_MOBILE + 20 + 'px',
              md: HEADER.H_MAIN_DESKTOP + 20 + 'px',
            },
            background: theme.palette.background.neutral,
            ...customLayoutStyle,
          }}
        >
          <Outlet
            context={{
              setCustomLayoutStyle,
            }}
          />
        </Box>
      </Box>
    </>
  );
}
