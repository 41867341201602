import { ApiResponse } from 'apisauce';

import { IPermission, TPermissionQueryParams } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import {
  TDeletePermissionResult,
  TGetPermissionResult,
  TGetPermissionsResult,
  TPostPermissionResult,
  TUpdatePermissionResult,
} from './PermissionTypes';

/**
 * # Permission Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new PermissionApi();
 *
 * ```
 */
export class PermissionApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(queryParams?: TPermissionQueryParams): Promise<TGetPermissionsResult> {
    try {
      const url = '/v1/permission';
      const params = queryParams;
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const permissions: IPermission[] = response.data.data;
      return { kind: 'ok', permissions };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(id: number): Promise<TGetPermissionResult> {
    try {
      const url = `/permission/${id}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const permission: IPermission = response.data.results;
      return { kind: 'ok', permission };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(): Promise<TPostPermissionResult> {
    try {
      const url = '/permission';
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const permission: IPermission = response.data.results;
      return { kind: 'ok', permission };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(permissions: IPermission[]): Promise<TUpdatePermissionResult> {
    try {
      const url = `/v1/permission`;
      const payload = permissions;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return { kind: 'ok' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(id: number): Promise<TDeletePermissionResult> {
    try {
      const url = `/permission/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return { kind: 'ok' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
