import { ElementType, lazy, Suspense } from 'react';

// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const Page403 = Loadable(lazy(() => import('../screens/common/Page403')));
export const Page404 = Loadable(lazy(() => import('../screens/common/Page404')));
export const Page500 = Loadable(lazy(() => import('../screens/common/Page500')));
export const ComingSoon = Loadable(lazy(() => import('../screens/common/ComingSoon')));
export const Maintenance = Loadable(lazy(() => import('../screens/common/Maintenance')));

export const Home = Loadable(lazy(() => import('../screens/home/Home')));
export const Dashboard = Loadable(lazy(() => import('../screens/dashboard/Dashboard')));
export const Login = Loadable(lazy(() => import('../screens/user/login/Login')));

export const Project = Loadable(lazy(() => import('../screens/projects/Projects')));
export const Calendar = Loadable(lazy(() => import('../screens/calendar/ProjectCalendar')));
export const Crm = Loadable(lazy(() => import('../screens/crm/Crm')));

export const Management = Loadable(lazy(() => import('../screens/management/Management')));

export const MyInfo = Loadable(lazy(() => import('../screens/user/my-info/MyInfo')));
