import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { DashboardOs } from '../DashboardOs';

/**
 * # Weight
 *
 * Weight을 설명하세요.
 */
export const Weight = types
  .model('Weight')
  // --------------------------------------------------------------------------
  .props({
    order: types.optional(types.array(DashboardOs), []),
    sales: types.optional(types.array(DashboardOs), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model weight --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IWeight;
      (Object.keys(newProps) as TWeightKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TWeight = Instance<typeof Weight>;
type TWeightSnapshot = SnapshotOut<typeof Weight>;

export interface IWeight extends TWeight {}
export type TWeightKeys = keyof TWeightSnapshot & string;
export interface IWeightSnapshot extends TWeightSnapshot {}
export const createWeight = () => types.optional(Weight, {} as TWeight);
