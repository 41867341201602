// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormControlLabel, FormControlLabelProps, FormHelperText, Switch } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends Omit<FormControlLabelProps, 'control'> {
  name: string;
  helperText?: React.ReactNode;
  color?:
    | 'error'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;
  // onChangeCallback?: (e: any) => void;
}

export default function RHFSwitch({
  name,
  helperText,
  color,
  // onChangeCallback,
  ...other
}: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                // onChange={(e) => {
                //   field.onChange({ target: { name, value: !field.value } });
                //   onChangeCallback && onChangeCallback({ target: { name, value: !field.value } });
                // }}
                color={color}
              />
            }
            {...other}
          />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </>
      )}
    />
  );
}
