import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Badge, Box, Link, ListItemText, Tooltip, useTheme } from '@mui/material';

// auth
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
// locales
import { useLocales } from '../../../locales';
//
import Iconify from '../../iconify';
//
import { NavItemProps } from '../types';

import { StyledDotIcon, StyledIcon, StyledItem } from './styles';

// ----------------------------------------------------------------------

export default function NavItem({
  item,
  depth,
  open,
  active,
  isExternalLink,
  ...other
}: NavItemProps) {
  const { translate } = useLocales();

  const theme = useTheme();

  const { title, path, icon, info, children, disabled, caption, roles, quarter } = item;

  const subItem = depth !== 1;

  const badgeColor =
    quarter === 2 ? 'error' : quarter === 3 ? 'warning' : quarter === 4 ? 'info' : 'secondary';

  const renderContent = (
    <StyledItem depth={depth} active={active} disabled={disabled} caption={!!caption} {...other}>
      {icon && <StyledIcon>{icon}</StyledIcon>}

      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}

      <Badge badgeContent={quarter} color={badgeColor} sx={{ '& .MuiBadge-badge': { right: -14 } }}>
        <ListItemText
          primary={`${translate(title)}`}
          secondary={
            caption && (
              <Tooltip title={`${translate(caption)}`} placement="top-start">
                <span>{`${translate(caption)}`}</span>
              </Tooltip>
            )
          }
          primaryTypographyProps={{
            noWrap: true,
            component: 'span',
            variant: active ? 'subtitle2' : 'body2',
            color: active ? theme.palette.text.primary : theme.palette.text.secondary,
            fontWeight: children ? 600 : 400,
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: 'caption',
          }}
        />
      </Badge>

      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {renderContent}
      </Link>
    );
  };

  return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
}
