import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { ModelCode } from '../model-code/ModelCode';

/**
 * # CommonCode
 *
 * CommonCode을 설명하세요.
 */
export const CommonCode = types
  .model('CommonCode')
  // --------------------------------------------------------------------------
  .props({
    name: types.string,
    list: types.array(ModelCode),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model commonCode --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICommonCode;
      (Object.keys(newProps) as TCommonCodeKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TCommonCode = Instance<typeof CommonCode>;
type TCommonCodeSnapshot = SnapshotOut<typeof CommonCode>;

export interface ICommonCode extends TCommonCode {}
export type TCommonCodeKeys = keyof TCommonCodeSnapshot & string;
export interface ICommonCodeSnapshot extends TCommonCodeSnapshot {}
export const createCommonCode = () => types.optional(CommonCode, {} as TCommonCode);
