import { Link as RouterLink } from 'react-router-dom';
import Logout from '@mui/icons-material/Logout';
import { Avatar, Box, IconButton, Link, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import moment from 'moment';
import { useStores } from 'src/models';

import { useAuthContext } from '../../../auth/useAuthContext';
import { CustomAvatar } from '../../../components/custom-avatar';
import { PATH_ROOT } from '../../../routes/paths';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  const rootStore = useStores();
  const { authStore } = rootStore;
  const loginUser = localStorage.loginUser && JSON.parse(localStorage.loginUser);
  const logout = async () => {
    await authStore.logout().then(() => {
      localStorage.removeItem('loginUser');
    });
  };

  return (
    <Link component={RouterLink} to={PATH_ROOT.myInfo.root} underline="none" color="inherit">
      <StyledRoot>
        {/* <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName} /> */}
        <Stack direction={'row'}>
          <Avatar />
          <Box sx={{ ml: 2, minWidth: 0 }}>
            <Typography variant="subtitle2" noWrap>
              {loginUser.userNm}
              {/* {user?.displayName || '이름없음'} */}
            </Typography>

            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {moment(loginUser.lastLoginDt).format('YY/MM/DD HH:mm')}
              {/* {user?.role || '권한없음'} */}
            </Typography>
          </Box>
        </Stack>
        {/* <IconButton onClick={logout}>
        <Logout fontSize="small" />
      </IconButton> */}
      </StyledRoot>
    </Link>
  );
}
