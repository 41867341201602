import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../user/SimpleUser';

/**
 * # DynamicCode
 *
 * DynamicCode을 설명하세요.
 */
export const DynamicCode = types
  .model('DynamicCode')
  // --------------------------------------------------------------------------
  .props({
    regDt: types.number,
    updDt: types.number,
    dynamicCd: createModelCode(),
    cdId: types.number,
    cdNm: types.string,
    value: types.maybeNull(types.string),
    ordr: types.number,
    useYn: types.boolean,
    dscrpt: types.maybeNull(types.string),
    regUser: createSimpleUser(),
    updUser: createSimpleUser(),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model dynamicCode --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IDynamicCode;
      (Object.keys(newProps) as TDynamicCodeKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TDynamicCode = Instance<typeof DynamicCode>;
type TDynamicCodeSnapshot = SnapshotOut<typeof DynamicCode>;

export interface IDynamicCode extends TDynamicCode {}
export type TDynamicCodeKeys = keyof TDynamicCodeSnapshot & string;
export interface IDynamicCodeSnapshot extends TDynamicCodeSnapshot {}
export const createDynamicCode = () => types.optional(DynamicCode, {} as TDynamicCode);
