import { toJS } from 'mobx';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { DataPermissionApi } from 'src/services/data-permission/DataPermission';
import {
  TGetDataPermissionsResult,
  TUpdateDataPermissionResult,
} from 'src/services/data-permission/DataPermissionTypes';

import { DataPermission, IDataPermission } from '../data-permission/DataPermission';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createPagination } from '../pagination/Pagination';

export const DataPermissionStore = types
  .model('DataPermissionStore')
  .props({
    pagination: createPagination(),
    permissions: types.optional(types.array(DataPermission), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({
    get paginationOptions() {
      return toJS(self.pagination);
    },
    get getDataPermissions() {
      return toJS(self.permissions);
    },
  }))
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * permissions을 교체
     *
     * @param `permissions` 새로운 모델의 배열
     */
    setPermissions: (permissions: IDataPermission[]) => {
      self.permissions.replace(permissions);
    },
  }))
  .actions((self) => ({
    gets: async () => {
      const permissionApi: DataPermissionApi = new DataPermissionApi(self.environment.api);
      const result: TGetDataPermissionsResult = await permissionApi.gets();
      if (result.kind === 'ok') {
        self.setPermissions(result.permissions);
      } else {
        console.error(result.kind);
      }
    },
    update: async (permissions: IDataPermission[]) => {
      const permissionApi: DataPermissionApi = new DataPermissionApi(self.environment.api);
      const result: TUpdateDataPermissionResult = await permissionApi.update(permissions);
      if (result.kind !== 'ok') {
        console.error(result.kind);
      }
    },
  }));

type TDataPermissionStore = Instance<typeof DataPermissionStore>;
type TDataPermissionStoreSnapshot = SnapshotOut<typeof DataPermissionStore>;

export interface IDataPermissionStore extends TDataPermissionStore {}
export type TDataPermissionStoreKeys = keyof TDataPermissionStoreSnapshot & string;
export interface IDataPermissionStoreSnapshot extends TDataPermissionStoreSnapshot {}
export const createPermissionStore = () =>
  types.optional(DataPermissionStore, {} as TDataPermissionStore);
