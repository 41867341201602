import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import {
  MngApi,
  TDeleteMngResult,
  TGetMngResult,
  TGetMngsResult,
  TPostMngResult,
  TUpdateMngResult,
} from '../../services';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createMng, IMng, IMngFilter, IMngSnapshot, Mng } from '../mng/Mng';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';

/**
 * # MngStore
 *
 * MngStore을 설명하세요.
 */
export const MngStore = types
  .model('MngStore')
  // --------------------------------------------------------------------------
  .props({
    pagination: createPagination(),
    mngs: types.optional(types.array(Mng), []),
    mng: createMng(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    resetPagination: () => {
      self.pagination = { size: 25, page: 1 } as IPagination;
    },
    setMngs: (mngs: IMng[]) => {
      self.mngs.replace(mngs);
    },
    setMng: (mng: IMngSnapshot) => {
      self.mng = mng as IMng;
    },
    resetMng: () => {
      self.mng = {} as IMng;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    gets: async (filter?: IMngFilter) => {
      const mngApi: MngApi = new MngApi(self.environment.api);
      const result: TGetMngsResult = await mngApi.gets(self.pagination, filter);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setMngs(result.mngs);
        result.pagination && self.setPagination(result.pagination);
      } else {
        console.error(result.kind);
      }
    },

    appendMngs: async (filter?: IMngFilter) => {
      try {
        const mngApi: MngApi = new MngApi(self.environment.api);
        const result: TGetMngsResult = await mngApi.gets(self.pagination, filter);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            self.setMngs(self.mngs.concat(result.mngs));
            result.pagination && self.setPagination(result.pagination);
          }
        }
      } catch (error) {
        self.rootStore.responseStore.errorProcessing(error);
      }
    },

    get: async (mngSid: number) => {
      const mngApi: MngApi = new MngApi(self.environment.api);
      const result: TGetMngResult = await mngApi.get(mngSid);
      self.rootStore.responseStore.getResponseResult(result);

      if (result.kind === 'ok') {
        self.setMng(result.mng);
      } else {
        console.error(result.kind);
      }
    },

    create: async (mng: IMngSnapshot, file?: File) => {
      const mngApi: MngApi = new MngApi(self.environment.api);
      const result: TPostMngResult = await mngApi.post(mng, file);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setMng(result.mng);
      } else {
        console.error(result.kind);
      }
    },

    update: async (mngSid: number, mng: IMngSnapshot, file?: File) => {
      const mngApi: MngApi = new MngApi(self.environment.api);
      const result: TUpdateMngResult = await mngApi.update(mngSid, mng, file);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setMng(result.mng);
      } else {
        console.error(result.kind);
      }
    },

    delete: async (mngSid: number) => {
      const mngApi: MngApi = new MngApi(self.environment.api);
      const result: TDeleteMngResult = await mngApi.delete(mngSid);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        return;
      } else {
        console.error(result.kind);
      }
    },

    deleteImg: async (mngSid: number) => {
      const mngApi: MngApi = new MngApi(self.environment.api);
      const result: TDeleteMngResult = await mngApi.deleteImg(mngSid);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        return;
      } else {
        console.error(result.kind);
      }
    },
  }));

// --------------------------------------------------------------------------
type TMngStore = Instance<typeof MngStore>;
type TMngStoreSnapshot = SnapshotOut<typeof MngStore>;

export interface IMngStore extends TMngStore {}
export type TMngStoreKeys = keyof TMngStoreSnapshot & string;
export interface IMngStoreSnapshot extends TMngStoreSnapshot {}
export const createMngStore = () => types.optional(MngStore, {} as TMngStore);
