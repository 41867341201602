import { Api } from '../services/api';

import { JEST_API_CONFIG, MOCK_API_CONFIG } from './../services/api/api-config';

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor(envType?: string) {
    if (envType === 'MOCK') {
      this.api = new Api(MOCK_API_CONFIG);
    } else if (envType === 'JEST') {
      console.log('Target Server ::: JEST');
      this.api = new Api(JEST_API_CONFIG);
    } else {
      this.api = new Api();
    }
  }

  async setup() {
    // allow each service to setup
    await this.api.setup();
  }

  /**
   * Our api.
   */
  api: Api;
}
