import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { RoundSalesList } from '../project/RoundSalesList';

/**
 * # ClassifiedRoundSales
 *
 * ClassifiedRoundSales을 설명하세요.
 */
export const ClassifiedRoundSales = types
  .model('ClassifiedRoundSales')
  // --------------------------------------------------------------------------
  .props({
    projectTypeCd: types.maybeNull(types.number),
    data: types.maybeNull(types.array(RoundSalesList)),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model classifiedRoundSales --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IClassifiedRoundSales;
      (Object.keys(newProps) as TClassifiedRoundSalesKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TClassifiedRoundSales = Instance<typeof ClassifiedRoundSales>;
type TClassifiedRoundSalesSnapshot = SnapshotOut<typeof ClassifiedRoundSales>;

export interface IClassifiedRoundSales extends TClassifiedRoundSales {}
export type TClassifiedRoundSalesKeys = keyof TClassifiedRoundSalesSnapshot & string;
export interface IClassifiedRoundSalesSnapshot extends TClassifiedRoundSalesSnapshot {}
export const createClassifiedRoundSales = () =>
  types.optional(ClassifiedRoundSales, {} as TClassifiedRoundSales);
