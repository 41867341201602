import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createYearGoal } from '../year-goal/YearGoal';

import { Government } from './Government';
import { OrderSales } from './OrderSales';

/**
 * # FcChart
 *
 * FcChart을 설명하세요.
 */
export const FcChart = types
  .model('FcChart')
  // --------------------------------------------------------------------------
  .props({
    fcDate: types.optional(types.number, 0),
    closedDt: types.maybeNull(types.number),
    isSnapshot: types.optional(types.boolean, false), // snapshot 여부
    isClosed: types.optional(types.boolean, false), // fcDate 월 마감여부 (true : 마감됨)
    canClosed: types.optional(types.boolean, false), // fcDate 월 마감가능 여부 (false : 마감 불가능)
    order: types.optional(types.array(OrderSales), []), // 수주
    sales: types.optional(types.array(OrderSales), []), // 매출
    govt: types.maybeNull(types.optional(types.array(Government), [])), // 정부과제

    isFinal: types.optional(types.boolean, false),
    yearGoal: types.maybeNull(createYearGoal()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model dashboard --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IFcChart;
      (Object.keys(newProps) as TFcChartKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TFcChart = Instance<typeof FcChart>;
type TFcChartSnapshot = SnapshotOut<typeof FcChart>;

export interface IFcChart extends TFcChart {}
export type TFcChartKeys = keyof TFcChartSnapshot & string;
export interface IFcChartSnapshot extends TFcChartSnapshot {}
export const createFcChart = () => types.optional(FcChart, {} as TFcChart);
