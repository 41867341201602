import React, { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
// @mui
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useSettings } from 'src/hooks/useSettings';
import { useStores } from 'src/models';
import { isMobile } from 'src/utils/common';
import * as Yup from 'yup';

import FormProvider, { RHFTextField } from '../../../components/hook-form';
// components
import Iconify from '../../../components/iconify';

import LoginMobile from './LoginMobile';

// ----------------------------------------------------------------------

type FormValuesProps = {
  userId: string;
  userPwd: string;
  afterSubmit?: string;
};

export default function Login() {
  const rootStore = useStores();
  const { authStore } = rootStore;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { themeMode, onChangeMode } = useSettings();

  // const { login } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    userId: Yup.string().required('아이디를 입력해주세요'),
    userPwd: Yup.string().required('비밀번호를 입력해주세요'),
  });

  const defaultValues = {
    userId: '', // 'admin',
    userPwd: '', // 'qhdks@00',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const loginSuccess = async () => {
    // await authStore.processAfterLoginSuccess({ loginId: 'admin' } as IUserSnapshot);
    await authStore.getLoginUserInfo();
  };

  const onSubmit = async (data: FormValuesProps) => {
    const userId = getValues('userId') ?? '';
    const userPwd = getValues('userPwd') ?? '';

    if (!checkParam(userId, userPwd)) {
      return;
    }

    (async () => {
      authStore.loginProcess(userId, userPwd).then(() => {
        const loginResponse = authStore.loginResponse;
        if (loginResponse) {
          if (loginResponse.loginResult === 'SUCCESS') {
            if (loginResponse.expired === true) {
              setIsAlertOpen(true);
            } else {
              loginSuccess();
            }
          } else {
            const message = loginResponse.errorMessage;
            enqueueSnackbar(
              message ? message : '로그인에 실패하였습니다. 관리자에게 문의 바랍니다.',
              { variant: 'error' },
            );
          }
        } else {
          return;
        }
      });
    })();
  };

  const checkParam = (userId: string, pwd: string) => {
    if (userId.length === 0) {
      enqueueSnackbar('아이디를 입력하세요.', { variant: 'warning' });
      return false;
    }
    if (pwd.length === 0) {
      enqueueSnackbar('비밀번호를 입력하세요.', { variant: 'warning' });
      return false;
    }
    return true;
  };

  useEffect(() => {
    const message = localStorage.getItem('messageInLoginPage') ?? '';
    if (message.length > 0) {
      enqueueSnackbar(message, { variant: 'info' });
      localStorage.removeItem('messageInLoginPage');
    }
  }, []);

  return isMobile() ? (
    <>
      <LoginMobile />
    </>
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ width: '50%', position: 'relative' }}>
          <Box
            component={'img'}
            src="assets/background/login.jpg"
            sx={{
              width: '100%',
              minHeight: '115%',
              objectFit: 'cover',
              position: 'absolute',
              transform: 'translateY(-10%)',
            }}
          />
        </Box>
        <Box
          component={'img'}
          src="assets/images/logo-color.svg"
          sx={{ width: 120, position: 'absolute', top: 40, left: 40, zIndex: 99 }}
        />
        <Box
          sx={{
            width: '50%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background: theme.palette.background.paper,
            p: 8,
            px: '7%',
          }}
        >
          <Stack direction={'row'}>
            <Typography variant="h2">Welcome Back</Typography>
          </Stack>
          <Typography variant="inherit" fontSize={'1.3rem'} mb={5}>
            <strong>F</strong>ore
            <strong>C</strong>ast your <strong>O</strong>rder & <strong>S</strong>ales
          </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ flex: 1, width: '100%' }}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

              <RHFTextField
                name="userId"
                label="Email address"
                variant={'outlined'}
                placeholder="이메일을 입력해 주세요."
              />

              <RHFTextField
                name="userPwd"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                variant={'outlined'}
                placeholder="비밀번호를 입력해 주세요."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <LoadingButton
              fullWidth
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              loading={!!isSubmitting}
              sx={{
                mt: 5,
                bgcolor: 'primary.main',
                borderRadius: 1,
                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                '&:hover': {
                  bgcolor: 'primary.main',
                  color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                },
              }}
            >
              Login
            </LoadingButton>
          </FormProvider>
          <Box position={'absolute'} sx={{ bottom: 20, right: 20 }}>
            <MaterialUISwitch
              checked={themeMode !== 'light'}
              onChange={() => {
                const val = themeMode === 'light' ? 'dark' : 'light';
                onChangeMode({
                  target: { value: val },
                } as unknown as React.ChangeEvent<HTMLInputElement>);
              }}
            />
          </Box>

          <Typography
            variant="inherit"
            textAlign={'center'}
            sx={{ position: 'absolute', right: '25%', bottom: 60, transform: 'translateX(50%)' }}
          >
            © 2024 INNERWAVE
          </Typography>
        </Box>
      </Box>
    </>
  );
}

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(2px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.primary.main, // theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 0.5,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    // border: theme.palette.mode === 'dark' ? `1px solid #aab4be` : `1px solid #8796A5`,
    borderRadius: 20 / 2,
  },
}));
