import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const PermissionList = types.model('PermissionList').props({
  canCreate: types.maybe(types.boolean),
  canRead: types.maybe(types.boolean),
  canUpdate: types.maybe(types.boolean),
  canDelete: types.maybe(types.boolean),
  menuNm: types.union(
    types.maybe(types.literal('FC차트')),
    types.maybe(types.literal('대시보드')),
    types.maybe(types.literal('프로젝트')),
    types.maybe(types.literal('캘린더')),
    types.maybe(types.literal('고객관리')),
    types.maybe(types.literal('시스템관리')),
  ),
  menuSid: types.number,
  securityLv: types.boolean,
});

export interface IPermissionListSnapshotIn extends SnapshotIn<typeof PermissionList> {}
export interface IPermissoinListSnapshotOut extends SnapshotOut<typeof PermissionList> {}
export type TPermissionListKeys = keyof IPermissoinListSnapshotOut;

/**
 * # Permission
 *
 * Permission을 설명하세요.
 */
export const Permission = types
  .model('Permission')
  // --------------------------------------------------------------------------
  .props({
    empPosNm: types.optional(types.string, ''),
    empPosSid: types.optional(types.number, 0),
    permissionList: types.optional(types.array(PermissionList), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model permission --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IPermission;
      (Object.keys(newProps) as TPermissionKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TPermission = Instance<typeof Permission>;
type TPermissionSnapshot = SnapshotOut<typeof Permission>;

export interface IPermission extends TPermission {}
export type TPermissionKeys = keyof TPermissionSnapshot & string;
export interface IPermissionSnapshot extends TPermissionSnapshot {}
export const createPermission = () => types.optional(Permission, {} as TPermission);

export interface IPermissionQueryParams {
  empPosSid: string;
  menuSid: string;
}

export type TPermissionQueryParams = Partial<IPermissionQueryParams>;
