import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { SpeedData } from './speed/SpeedData';

/**
 * # DashboardSp
 *
 * DashboardSp을 설명하세요.
 */
export const DashboardSp = types
  .model('DashboardSp')
  // --------------------------------------------------------------------------
  .props({
    name: types.maybeNull(types.string),
    data: types.maybeNull(types.optional(types.array(SpeedData), [])),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model dashboardSp --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IDashboardSp;
      (Object.keys(newProps) as TDashboardSpKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TDashboardSp = Instance<typeof DashboardSp>;
type TDashboardSpSnapshot = SnapshotOut<typeof DashboardSp>;

export interface IDashboardSp extends TDashboardSp {}
export type TDashboardSpKeys = keyof TDashboardSpSnapshot & string;
export interface IDashboardSpSnapshot extends TDashboardSpSnapshot {}
export const createDashboardSp = () => types.optional(DashboardSp, {} as TDashboardSp);
