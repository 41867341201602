// routes
import { ReactComponent as IcoDashboard } from 'src/assets/images/icons/ic_analytics.svg';
// components
import { ReactComponent as IcoProjects } from 'src/assets/images/icons/ic_blog.svg';
import { ReactComponent as IcoCalendar } from 'src/assets/images/icons/ic_calendar.svg';
import { ReactComponent as IcoChart } from 'src/assets/images/icons/ic_chart.svg';
import { ReactComponent as IcoSetting } from 'src/assets/images/icons/ic_setting.svg';

import { PATH_ROOT } from '../../../routes/paths';
// ----------------------------------------------------------------------

const navConfig = [
  {
    path: PATH_ROOT.fcChart.root,
    title: 'FC차트',
    enTitle: 'FC CHART',
    icon: IcoChart,
    isAdmin: false,
    // isDesktop: true,
  },
  {
    path: PATH_ROOT.dashboard.root,
    title: '대시보드',
    enTitle: 'DASHBOARD',
    icon: IcoDashboard,
    isAdmin: false,
    // isDesktop: true,
  },
  {
    path: PATH_ROOT.project.root,
    title: '프로젝트',
    enTitle: 'PROJECT',
    icon: IcoProjects,
    isAdmin: false,
    // isDesktop: true,
  },
  {
    path: PATH_ROOT.calendar.root,
    title: '캘린더',
    enTitle: 'SCHEDULE',
    icon: IcoCalendar,
    isAdmin: false,
    // isDesktop: true,
  },
  {
    path: PATH_ROOT.crm.root,
    title: '고객관리',
    enTitle: 'CRM',
    icon: IcoCalendar,
    isAdmin: false,
    // isDesktop: true,
  },
  {
    path: PATH_ROOT.management.root,
    title: '시스템관리',
    enTitle: 'SYSTEM MGMT',
    icon: IcoSetting,
    isAdmin: true,
    // isDesktop: true,

    children: [
      // {
      // subheader: '',
      // items: [
      {
        title: '사용자관리',
        path: PATH_ROOT.management.user,
      },
      {
        title: '권한설정관리',
        path: PATH_ROOT.management.permission,
      },
      {
        title: '권한그룹관리',
        path: PATH_ROOT.management.group,
      },
      {
        title: '메뉴권한관리',
        path: PATH_ROOT.management.menu,
      },
      {
        title: '시스템설정',
        path: PATH_ROOT.management.setting,
      },
      // ],
      // },
    ],
  },
];

export default navConfig;
