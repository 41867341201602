import { ApiResponse } from 'apisauce';
import { RESULT_CODE } from 'src/components/settings/constEnum';
import { IPaginationSnapshot } from 'src/models/pagination/Pagination';
import { IResponseSnapshot } from 'src/models/response/Response';
import { IUserFilter } from 'src/models/user-store/user-store';
import { MyInfoForm } from 'src/screens/user/my-info/MyInfo';

import { getGeneralApiProblem } from '../../services/api';
import {
  GetDeleteUserResult,
  GetLoginIDCheckResult,
  GetPostUserResult,
  GetPutUserResult,
  GetUserResult,
  GetUsersResult,
} from '../../services/user/user-result-types';
import { Api } from '../api/api';

import { IUserSnapshot } from './../../models/user/user';

export class UserApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(paging: IPaginationSnapshot, params?: IUserFilter): Promise<GetUsersResult> {
    try {
      // const SUB_URL = '/v1/user';
      let url;

      if (params) {
        // @ts-ignore
        const queryParams = new URLSearchParams(params).toString();
        url = `/v2/user?page=${paging.page}&size=${paging.size}&${queryParams}`;
      } else {
        url = `/v2/user?page=${paging.page}&size=${paging.size}`;
      }
      const response: ApiResponse<any> = await this.api.apisauce.get(url);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return { kind: 'ok', responseInfo: responseInfo, users: response.data.data };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(userSid: number): Promise<GetUserResult> {
    try {
      const SUB_URL = '/v2/user/' + userSid;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return { kind: 'ok', responseInfo: responseInfo, user: response.data.data };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(user: IUserSnapshot): Promise<GetPostUserResult> {
    try {
      const SUB_URL = '/v2/user';
      const sendData = createSendData(user);

      const response: ApiResponse<any> = await this.api.apisauce.post(SUB_URL, sendData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return { kind: 'ok', responseInfo: responseInfo, user: response.data.data };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async put(user: IUserSnapshot): Promise<GetPutUserResult> {
    try {
      const SUB_URL = '/v2/user/' + user.userSid;
      const sendData = createSendData(user);
      const response: ApiResponse<any> = await this.api.apisauce.put(SUB_URL, sendData);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === 'S') {
        return { kind: 'ok', responseInfo: responseInfo, user: response.data.data };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(userSid: number): Promise<GetDeleteUserResult> {
    try {
      const SUB_URL = '/v1/user/' + userSid;

      const response: ApiResponse<any> = await this.api.apisauce.delete(SUB_URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getMyInfo(): Promise<GetUserResult> {
    try {
      const URL = `/v2/myInfo`;
      const response: ApiResponse<any> = await this.api.apisauce.get(URL);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        if (response.data.data) {
          return {
            kind: 'ok',
            responseInfo: responseInfo,
            user: response.data.data,
          };
        }
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return {
        kind: 'bad-data',
      };
    }
  }

  async putMyInfo(user: MyInfoForm): Promise<GetPutUserResult> {
    try {
      const SUB_URL = '/v2/myInfo';
      const response: ApiResponse<any> = await this.api.apisauce.put(SUB_URL, user);
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      if (responseInfo.resultCode === 'S') {
        return { kind: 'ok', responseInfo: responseInfo, user: response.data.data };
      }
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async checkLoginId(loginId: string): Promise<GetLoginIDCheckResult> {
    try {
      const SUB_URL = `/v1/user/loginId/check?loginId=${loginId}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(SUB_URL);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      if (responseInfo.resultCode === RESULT_CODE.SUCCESS) {
        return { kind: 'ok', responseInfo: responseInfo, result: response.data.data.result };
      }

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}

type requestBodyType = {
  userSid?: number;
  userId?: number;
  loginId?: string;
  userName?: string;
  pwd?: string;
  pwdCorrect?: string;
  sessn: number;
};

const createSendData = (user: IUserSnapshot) => {
  return user;
};
