import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isNull } from 'lodash';
import { useStores } from 'src/models';
import Login from 'src/screens/user/login/Login';

// components
import LoadingScreen from '../components/loading-screen';

//
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, isAuthenticated, isInitialized } = useAuthContext();
  const { authStore } = useStores();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  // if (isNull(user)) {
  //   return <Login />;
  // } else if (isNull(authStore.getLoginUserStorageData())) {
  //   // SessionContext user 업데이트 안쳐졌을 경우를 대비해서.
  //   return <Login />;
  // }
  return <> {children} </>;
}
