import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # SfData
 *
 * SfData을 설명하세요.
 */
export const SfData = types
  .model('SfData')
  // --------------------------------------------------------------------------
  .props({
    name: types.maybeNull(types.string),
    amt: types.maybeNull(types.optional(types.number, 0)),
    tooltip: types.maybeNull(types.array(types.string)),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model sfData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISfData;
      (Object.keys(newProps) as TSfDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSfData = Instance<typeof SfData>;
type TSfDataSnapshot = SnapshotOut<typeof SfData>;

export interface ISfData extends TSfData {}
export type TSfDataKeys = keyof TSfDataSnapshot & string;
export interface ISfDataSnapshot extends TSfDataSnapshot {}
export const createSfData = () => types.optional(SfData, {} as TSfData);
