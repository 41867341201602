import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { NonEmptyObject, SnapshotIn } from 'mobx-state-tree/dist/internal';

export const FilteringConditions = types
  .model('FilteringConditions')
  .props({
    deprtCd: types.maybe(types.union(types.number, types.literal('전체'))),
    bzStepCd: types.maybe(types.union(types.number, types.literal('전체'))),
    dateType: types.maybe(types.union(types.literal('order'), types.literal('sales'))),
    startDate: types.maybe(types.number),
    endDate: types.maybe(types.number),
    minPrice: types.maybe(types.number),
    maxPrice: types.maybe(types.number),
  })
  .actions((self) => ({}));

type TFilteringConditions = Instance<typeof FilteringConditions>;
type TFilteringConditionsSnapshotIn = SnapshotIn<typeof FilteringConditions>;
type TFilteringConditionsSnapshotOut = SnapshotOut<typeof FilteringConditions>;

export interface IFilteringConditions extends TFilteringConditions, NonEmptyObject {
  [key: string]: string | number | undefined;
}
export type TFilteringConditionsKeys = keyof TFilteringConditionsSnapshotOut;
export interface IFilterConditionsSnapshotIn extends TFilteringConditionsSnapshotIn {}
export interface IFilteringConditionsSnapshotOut extends TFilteringConditionsSnapshotOut {}
