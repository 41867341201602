import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';
import { Box, IconButton, List, Stack, useTheme } from '@mui/material';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useSettings } from 'src/hooks/useSettings';

import { useLocales } from '../../../locales';
import { NavSectionProps } from '../types';

import NavList from './NavList';
import { StyledSubheader } from './styles';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  const { translate } = useLocales();
  const { user } = useAuthContext();

  const theme = useTheme();
  const ACCESSIBLE_MENU_URI_LIST = user?.menuList.map((m: any) => m.menuUri);

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          group.items
            .map((list) => ACCESSIBLE_MENU_URI_LIST.includes(list.path))
            .includes(true) && (
            <List
              key={key}
              disablePadding
              sx={{ px: 2, ml: 1, mr: 1, borderBottom: '1px solid ' + theme.palette.divider }}
            >
              {/* {group.subheader && (
                <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
              )} */}

              {group.items.map((list) => {
                return (
                  ACCESSIBLE_MENU_URI_LIST.includes(list.path) && (
                    <NavList
                      key={list.title + list.path}
                      data={list}
                      depth={1}
                      hasChild={!!list.children}
                    />
                  )
                );
              })}
            </List>
          )
        );
      })}
      {/* <Stack sx={{ height: 100, p: 2, pl: 5, justifyContent: 'right' }}>
      </Stack> */}
    </Stack>
  );
}
