import { create } from 'lodash';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../model-code/ModelCode';
import { UserGroup } from '../user-group/UserGroup';

import { createSimpleUser } from './SimpleUser';

/**
 * Model description here for TypeScript hints.
 */

export const UserModel = types
  .model('User')
  .props({
    userSid: types.maybeNull(types.optional(types.number, 0)),
    loginId: types.maybeNull(types.optional(types.string, '')),
    userToken: types.maybeNull(types.optional(types.string, '')),
    userNm: types.optional(types.string, ''),
    acntSttsCd: createModelCode(),
    loginPrvntSttsCd: createModelCode(),

    acntDsabldDt: types.maybeNull(types.string),
    acntExpDt: types.maybeNull(types.string),
    acntLockDt: types.maybeNull(types.string),
    // acntSttsCd: createModelCode(),
    acntttsCdList: types.maybeNull(types.string),
    allUserGroupRoleId: types.maybeNull(types.string),
    beforePwd: types.maybeNull(types.string),
    externalLinkYn: types.optional(types.boolean, false),
    lastLoginDt: types.maybeNull(types.optional(types.number, 0)),
    loginFailCnt: types.maybeNull(types.number),
    // loginId: types.maybeNull(types.optional(types.string, '')),
    // loginPrvntSttsCd: createModelCode(),
    logiTm1sFailDt: types.maybeNull(types.string),
    // menuList: types.maybeNull(types.optional(types.array(), [])),
    pwd: types.maybeNull(types.string),
    pwdCorrect: types.maybeNull(types.string),
    lastlyPwd: types.maybeNull(types.string),
    pwdUpdDt: types.maybeNull(types.optional(types.number, 0)),
    // regDt: types.maybeNull(types.optional(types.number, 0)),
    regUser: types.maybeNull(createSimpleUser()),
    sessn: types.maybeNull(types.string),
    svcSttsCd: createModelCode(),
    // updDt: types.maybeNull(types.optional(types.number, 0)),
    updUser: types.maybeNull(createSimpleUser()),
    userGroupSid: types.maybeNull(types.string),
    userGroupList: types.maybeNull(types.optional(types.array(UserGroup), [])),
    userGroupSidList: types.maybeNull(types.optional(types.array(types.number), [])),

    whtdDt: types.maybeNull(types.string),
    whtdYn: types.optional(types.boolean, false),

    regDt: types.maybeNull(types.optional(types.number, 0)),
    updDt: types.maybeNull(types.optional(types.number, 0)),

    deprtCd: types.maybeNull(createModelCode()),
    // 메일구독 관련
    emailAddr: types.maybeNull(types.string),
    subTime: types.maybeNull(types.number || types.string),
    subDailyYn: types.maybeNull(types.optional(types.boolean, false)),
    subWeeklyYn: types.maybeNull(types.optional(types.boolean, false)),
    subDayWeekNums: types.maybeNull(types.optional(types.array(types.number), [])),
    subMonthlyYn: types.maybeNull(types.optional(types.boolean, false)),
    subDayNums: types.maybeNull(types.optional(types.array(types.number), [])),
  })
  .views((self) => ({}))
  .actions((self) => ({
    resetUser: () => {
      self.userSid = 0;
      self.loginId = '';
      self.userToken = '';
      self.userNm = '';
    },

    setProps: (props: { [key: string]: any }) => {
      const updated = { ...self, ...props } as IUser;
      (Object.keys(updated) as TUserTypeKey[]).forEach((key) => {
        if (typeof self[key] === typeof updated[key]) {
          // @ts-ignore
          self[key] = updated[key];
        } else {
          throw new Error(`${key.toString} Input Type Error`);
        }
      });
    },
  }));

type TUserType = Instance<typeof UserModel>;
type TUserSnapshotType = SnapshotOut<typeof UserModel>;

export interface IUser extends TUserType {}
export type TUserTypeKey = keyof TUserSnapshotType & string;
export interface IUserSnapshot extends TUserSnapshotType {}
export const createUserDefaultModel = () => types.optional(UserModel, {});
