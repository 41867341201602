import { ApiResponse } from 'apisauce';
import { IYearGoal, IYearGoalSnapshot } from 'src/models';
import { IResponse } from 'src/models/response/Response';

import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import { TDeleteYearGoalResult, TGetYearGoalResult, TSaveYearGoalResult } from './YearGoalTypes';

/**
 * # YearGoal Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new YearGoalApi();
 *
 * ```
 */
export class YearGoalApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async get(year: number): Promise<TGetYearGoalResult> {
    try {
      const url = `/v1/year-goal?year=${year}`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url);
      // console.log('🪄 ~ YearGoalApi ~ get ~ response:', response);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const yearGoal: IYearGoal = response.data.data;
      const responseInfo: IResponse = response.data;
      return { kind: 'ok', yearGoal, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(data: IYearGoalSnapshot): Promise<TSaveYearGoalResult> {
    try {
      const url = '/v1/year-goal';
      const payload = data;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const yearGoal: IYearGoal = response.data.data;
      const responseInfo: IResponse = response.data;
      return { kind: 'ok', yearGoal, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(data: IYearGoalSnapshot): Promise<TSaveYearGoalResult> {
    try {
      const url = `/v1/year-goal`;
      const payload = data;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const yearGoal: IYearGoal = response.data.data;
      const responseInfo: IResponse = response.data;
      return { kind: 'ok', yearGoal, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(year: number): Promise<TDeleteYearGoalResult> {
    try {
      const url = `/v1/year-goal/${year}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponse = response.data;
      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
