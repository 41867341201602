import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { WeightData } from './weight/WeightData';

/**
 * # DashboardOs
 *
 * DashboardOs을 설명하세요.
 */
export const DashboardOs = types
  .model('DashboardOs')
  // --------------------------------------------------------------------------
  .props({
    name: types.maybeNull(types.string),
    data: types.maybeNull(types.optional(types.array(WeightData), [])),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model dashboardOs --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IDashboardOs;
      (Object.keys(newProps) as TDashboardOsKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TDashboardOs = Instance<typeof DashboardOs>;
type TDashboardOsSnapshot = SnapshotOut<typeof DashboardOs>;

export interface IDashboardOs extends TDashboardOs {}
export type TDashboardOsKeys = keyof TDashboardOsSnapshot & string;
export interface IDashboardOsSnapshot extends TDashboardOsSnapshot {}
export const createDashboardOs = () => types.optional(DashboardOs, {} as TDashboardOs);
