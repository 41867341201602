import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createCustomer } from '../customer/Customer';
import { createSimpleUser } from '../user/SimpleUser';

/**
 * # Mng
 *
 * Mng을 설명하세요.
 */
export const Mng = types
  .model('Mng')
  // --------------------------------------------------------------------------
  .props({
    mngSid: types.optional(types.number, 0),
    mngNm: types.optional(types.string, ''),
    mngDeprtNm: types.maybeNull(types.optional(types.string, '')),
    mngEmpPos: types.maybeNull(types.optional(types.string, '')),
    mngPh: types.maybeNull(types.optional(types.string, '')),
    mngEmail: types.maybeNull(types.optional(types.string, '')),
    mngMemo: types.maybeNull(types.optional(types.string, '')),
    mngImgUrl: types.maybeNull(types.optional(types.string, '')),
    delYn: types.optional(types.boolean, false),
    customer: types.maybeNull(createCustomer()),
    customerSid: types.maybeNull(types.optional(types.number, 0)),
    company: types.maybeNull(types.optional(types.string, '')),
    regDt: types.maybeNull(types.number),
    updDt: types.maybeNull(types.number),
    regUser: createSimpleUser(),
    updUser: createSimpleUser(),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model mng --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IMng;
      (Object.keys(newProps) as TMngKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TMng = Instance<typeof Mng>;
type TMngSnapshot = SnapshotOut<typeof Mng>;

export interface IMng extends TMng {}
export type TMngKeys = keyof TMngSnapshot & string;
export interface IMngSnapshot extends TMngSnapshot {}
export const createMng = () => types.optional(Mng, {} as TMng);

export interface IMngFilter {
  text?: string;

  page?: number;
  size?: number;
}

export type TMngFilter = IMngFilter;
