import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import {
  TDeleteUserGroupResult,
  TGetUserGroupResult,
  TGetUserGroupsMenuResult,
  TGetUserGroupsResult,
  TPostUserGroupResult,
  TUpdateUserGroupResult,
  TUpdateUserGroupsMenuResult,
  UserGroupApi,
} from 'src/services';

import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';
import {
  createUserGroup,
  IUserGroup,
  IUserGroupFilter,
  IUserGroupSnapshot,
  UserGroup,
} from '../user-group/UserGroup';

/**
 * # UserGroupStore
 *
 * UserGroupStore을 설명하세요.
 */
export const UserGroupStore = types
  .model('UserGroupStore')
  // --------------------------------------------------------------------------
  .props({
    pagination: createPagination(),
    userGroups: types.optional(types.array(UserGroup), []),
    selectedGroups: types.optional(types.array(UserGroup), []),
    userGroup: createUserGroup(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    /**
     * userGroups을 교체
     *
     * @param `userGroups` 새로운 모델의 배열
     */
    setUserGroups: (userGroups: IUserGroup[]) => {
      self.userGroups.replace(userGroups);
    },
    setSelectedUserGroups: (userGroups: IUserGroup[]) => {
      self.selectedGroups.replace(userGroups);
    },
    resetUserGroups: () => {
      self.userGroups.replace([]);
    },
    setUserGroup: (userGroup: IUserGroup) => {
      self.userGroup = userGroup as IUserGroup;
    },
    resetUserGroup: () => {
      self.userGroup = {} as IUserGroup;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 UserGroups를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async (filter?: IUserGroupFilter) => {
      const userGroupApi: UserGroupApi = new UserGroupApi(self.environment.api);
      const result: TGetUserGroupsResult = await userGroupApi.gets(self.pagination, filter);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setUserGroups(result.userGroups);
      } else {
        console.error(result.kind);
      }
    },

    userGroupMenuRel: async (userGroupSid: number) => {
      const userGroupApi: UserGroupApi = new UserGroupApi(self.environment.api);
      const result: TGetUserGroupsMenuResult = await userGroupApi.userGroupMenuRel(userGroupSid);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setSelectedUserGroups(result.userGroups);
      } else {
        console.error(result.kind);
      }
    },

    updateUserGroupMenu: async (userGroupSid: number, menuSidList: number[]) => {
      const userGroupApi: UserGroupApi = new UserGroupApi(self.environment.api);
      const result: TUpdateUserGroupsMenuResult = await userGroupApi.updateUserGroupMenu(
        userGroupSid,
        menuSidList,
      );
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setSelectedUserGroups(result.userGroups);
      } else {
        console.error(result.kind);
      }
    },

    get: async (userGroupSid: number) => {
      const userGroupApi: UserGroupApi = new UserGroupApi(self.environment.api);
      const result: TGetUserGroupResult = await userGroupApi.get(userGroupSid);
      self.rootStore.responseStore.getResponseResult(result);
      self.resetUserGroup();
      if (result.kind === 'ok') {
        self.setUserGroup(result.userGroup);
      } else {
        console.error(result.kind);
      }
    },

    post: async (userGroup: IUserGroupSnapshot) => {
      try {
        const userGroupApi: UserGroupApi = new UserGroupApi(self.environment.api);
        const result: TPostUserGroupResult = await userGroupApi.post(userGroup);
        self.rootStore.responseStore.getResponseResult(result);
        self.resetUserGroup();
        if (result.kind === 'ok') {
          result.userGroup && self.setUserGroup(result.userGroup);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    put: async (userGroup: IUserGroupSnapshot) => {
      try {
        const userGroupApi: UserGroupApi = new UserGroupApi(self.environment.api);

        const result: TUpdateUserGroupResult = await userGroupApi.update(userGroup);
        self.rootStore.responseStore.getResponseResult(result);
        self.resetUserGroup();
        if (result.kind === 'ok') {
          result.userGroup && self.setUserGroup(result.userGroup);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    delete: async (userSid: number) => {
      try {
        const userGroupApi: UserGroupApi = new UserGroupApi(self.environment.api);

        const result: TDeleteUserGroupResult = await userGroupApi.delete(userSid);
        self.rootStore.responseStore.getResponseResult(result);
        self.resetUserGroup();
        if (result.kind === 'ok') {
          return;
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TUserGroupStore = Instance<typeof UserGroupStore>;
type TUserGroupStoreSnapshot = SnapshotOut<typeof UserGroupStore>;

export interface IUserGroupStore extends TUserGroupStore {}
export type TUserGroupStoreKeys = keyof TUserGroupStoreSnapshot & string;
export interface IUserGroupStoreSnapshot extends TUserGroupStoreSnapshot {}
export const createUserGroupStore = () => types.optional(UserGroupStore, {} as TUserGroupStore);
