import { ApiResponse } from 'apisauce';
import { IFailSuccess, ISpeed, IWeight } from 'src/models';

import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import { TGetFsResult, TGetSpeedResult, TGetWeightResult } from './DashboardTypes';

/**
 * # Dashboard Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new DashboardApi();
 *
 * ```
 */
export class DashboardApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getMargin(type: string, deprtCd?: number): Promise<TGetWeightResult> {
    try {
      const url = `/v1/dashboard/weight/${type}/margin${deprtCd ? '?deprtCd=' + deprtCd : ''}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo = response.data;
      const dashboards: IWeight = response.data.data;
      return { kind: 'ok', dashboards, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getWeight(type: string, deprtCd?: number): Promise<TGetWeightResult> {
    try {
      const url = `/v1/dashboard/weight/${type}${deprtCd ? '?deprtCd=' + deprtCd : ''}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo = response.data;
      const dashboards: IWeight = response.data.data;
      return { kind: 'ok', dashboards, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getSpeed(quarterNum: number, deprtCd?: number): Promise<TGetSpeedResult> {
    try {
      const url = `/v1/dashboard/speed/${quarterNum}${deprtCd ? '?deprtCd=' + deprtCd : ''}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo = response.data;
      const dashboards: ISpeed = response.data.data;
      return { kind: 'ok', dashboards, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
  async getFs(quarter: number, deprtCd?: number): Promise<TGetFsResult> {
    try {
      const url = `/v1/dashboard/fs/${quarter}${deprtCd ? '?deprtCd=' + deprtCd : ''}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo = response.data;
      const dashboards: IFailSuccess = response.data.data;
      return { kind: 'ok', dashboards, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
