import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # ChartData
 *
 * ChartData을 설명하세요.
 */
export const ChartData = types
  .model('ChartData')
  // --------------------------------------------------------------------------
  .props({
    amt: types.maybeNull(types.number),
    tooltip: types.maybeNull(types.optional(types.array(types.string), [])),
    searchSidList: types.maybeNull(types.optional(types.array(types.number), [])),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model chartData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IChartData;
      (Object.keys(newProps) as TChartDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TChartData = Instance<typeof ChartData>;
type TChartDataSnapshot = SnapshotOut<typeof ChartData>;

export interface IChartData extends TChartData {}
export type TChartDataKeys = keyof TChartDataSnapshot & string;
export interface IChartDataSnapshot extends TChartDataSnapshot {}
export const createChartData = () => types.optional(ChartData, {} as TChartData);
