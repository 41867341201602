import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../model-code/ModelCode';
import { createUserDefaultModel } from '../user/user';

/**
 * # OrderList
 *
 * OrderList을 설명하세요.
 */
export const OrderList = types
  .model('OrderList')
  // --------------------------------------------------------------------------
  .props({
    orderAmt: types.maybeNull(types.number),
    projectSid: types.optional(types.number, 0),
    projectTypeCd: createModelCode(),
    regDt: types.optional(types.number, 0),
    updDt: types.optional(types.number, 0),
    regUser: types.maybeNull(createUserDefaultModel()),
    updUser: types.maybeNull(createUserDefaultModel()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model orderList --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IOrderList;
      (Object.keys(newProps) as TOrderListKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TOrderList = Instance<typeof OrderList>;
type TOrderListSnapshot = SnapshotOut<typeof OrderList>;

export interface IOrderList extends TOrderList {}
export type TOrderListKeys = keyof TOrderListSnapshot & string;
export interface IOrderListSnapshot extends TOrderListSnapshot {}
export const createOrderList = () => types.optional(OrderList, {} as TOrderList);
