import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../user/SimpleUser';

/**
 * # Customer
 *
 * Customer을 설명하세요.
 */
export const Customer = types
  .model('Customer')
  // --------------------------------------------------------------------------
  .props({
    customerSid: types.optional(types.number, 0),
    company: types.optional(types.string, ''),
    divisionCd: types.maybeNull(createModelCode()),
    gradeCd: types.maybeNull(createModelCode()),
    progressCd: types.maybeNull(createModelCode()),
    sales: types.maybeNull(types.optional(types.number, 0)),
    empCnt: types.maybeNull(types.optional(types.number, 0)),
    businessNum: types.maybeNull(types.optional(types.string, '')),
    telNo: types.maybeNull(types.optional(types.string, '')),
    faxNo: types.maybeNull(types.optional(types.string, '')),
    webSite: types.maybeNull(types.optional(types.string, '')),
    addr: types.maybeNull(types.optional(types.string, '')),
    addrDetail: types.maybeNull(types.optional(types.string, '')),
    compMemo: types.maybeNull(types.optional(types.string, '')),
    delYn: types.maybeNull(types.optional(types.boolean, false)),
    regDt: types.maybeNull(types.number),
    updDt: types.maybeNull(types.number),
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model customer --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICustomer;
      (Object.keys(newProps) as TCustomerKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TCustomer = Instance<typeof Customer>;
type TCustomerSnapshot = SnapshotOut<typeof Customer>;

export interface ICustomer extends TCustomer {}
export type TCustomerKeys = keyof TCustomerSnapshot & string;
export interface ICustomerSnapshot extends TCustomerSnapshot {}
export const createCustomer = () => types.optional(Customer, {} as TCustomer);

export interface ICustomerFilter {
  text?: string;

  page?: number;
  size?: number;
}

export type TCustomerFilter = ICustomerFilter;
