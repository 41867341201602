import { toJS } from 'mobx';
import { applySnapshot, getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree';

import {
  ProjectApi,
  TDeleteProjectResult,
  TGetCalendarResult,
  TGetProjectResult,
  TGetProjectsResult,
  TPostProjectResult,
  TUpdateProjectResult,
} from '../../services';
import { Calendar, ICalendar } from '../calendar/Calendar';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import {
  createPagination,
  createPaginationForMobile,
  IPagination,
  IPaginationSnapshot,
} from '../pagination/Pagination';
import {
  createProject,
  IProject,
  IProjectFilter,
  IProjectSnapshot,
  Project,
} from '../project/Project';
import {
  FilteringConditions,
  IFilterConditionsSnapshotIn,
  IFilteringConditions,
} from '../project-filter-conditions/ProjectFilterCondition';

/**
 * # ProjectStore
 *
 * ProjectStore을 설명하세요.
 */
export const ProjectStore = types
  .model('ProjectStore')
  // --------------------------------------------------------------------------
  .props({
    pagination: createPagination(),
    paginationForMobile: createPaginationForMobile(),
    appendedProjects: types.optional(types.array(Project), []), // 모바일에서 보여지는 프로젝트 리스트
    projects: types.optional(types.array(Project), []), // 데스크탑에서 보여지는 프로젝트 리스트
    project: createProject(),
    filterByConditions: types.optional(FilteringConditions, {}),
    sortByConditions: types.optional(types.string, 'order'),
    searchByKeyword: types.optional(types.string, ''),
    calendarData: types.optional(types.array(Calendar), []),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({
    get projectList() {
      return toJS(self.projects);
    },
    get appendedProjectList() {
      return toJS(self.appendedProjects);
    },
    get paginationOptions() {
      return toJS(self.pagination);
    },
    get paginationOptionsForMobile() {
      return toJS(self.paginationForMobile);
    },
    get getFilterByConditions() {
      return toJS(self.filterByConditions);
    },
    get getQueryParams(): IProjectFilter {
      const sort = toJS(self.sortByConditions);
      const filteringConditions = toJS(self.filterByConditions);
      const search = toJS(self.searchByKeyword);
      let filterConditionsObj = {};

      Object.keys(filteringConditions)
        /** FilteringConditions 오브젝트의 value값이 undefined인 것 제외 */
        .filter((key) => (filteringConditions as any)[key] !== undefined)
        /** [{ deprtCd: number }, { bzStepCd: number }]을 추상화 */
        .map((key) => ({ [key]: (filteringConditions as any)[key] }))
        .forEach((conditions) => {
          filterConditionsObj = { ...filterConditionsObj, ...conditions };
        });

      return {
        ...filterConditionsObj,
        sortBy: sort,
        text: search,
      };
    },
  }))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * projects을 교체
     *
     * @param `projects` 새로운 모델의 배열
     */
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setPaginationForMobile: (pagination: IPaginationSnapshot) => {
      self.paginationForMobile = pagination as IPagination;
    },
    setProjects: (projects: IProject[]) => {
      self.projects.replace(projects);
    },
    setAppendProjects: (projects: IProject[]) => {
      const currentAppendedProjects = getSnapshot(self.appendedProjects);
      const newAppendedProjects = [...currentAppendedProjects, ...projects];
      applySnapshot(self.appendedProjects, newAppendedProjects);
    },
    removeProject: (projectSid: number) => {
      const currentAppendedProjects = getSnapshot(self.appendedProjects);
      const removeAndNewAppendedProjects = currentAppendedProjects.filter(
        (project) => project.projectSid !== projectSid,
      );
      applySnapshot(self.appendedProjects, removeAndNewAppendedProjects);
    },
    setFilterByConditions: (conditions: IFilterConditionsSnapshotIn) => {
      self.filterByConditions = conditions as IFilteringConditions;
    },
    setOrderByConditions: (conditions: string) => {
      self.sortByConditions = conditions;
    },
    setSearchByKeyword: (keyword: string) => {
      self.searchByKeyword = keyword;
    },
    resetProjects: () => {
      self.projects.replace([]);
    },
    resetAppendedProjects: () => {
      self.appendedProjects.replace([]);
    },
    setProject: (project: IProjectSnapshot) => {
      self.project = project as IProject;
    },
    resetProject: () => {
      self.project = {} as IProject;
    },
    setCalendarData: (calendarData: ICalendar[]) => {
      self.calendarData.replace(calendarData);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 프로젝트 목록 조회
     * @param filter
     */
    gets: async (filter?: IProjectFilter) => {
      try {
        const projectApi: ProjectApi = new ProjectApi(self.environment.api);
        const result: TGetProjectsResult = await projectApi.gets(
          {
            ...self.pagination,
            page: filter?.page || self.pagination.page,
            size: filter?.size || self.pagination.size,
          },
          filter,
        );
        self.rootStore.responseStore.getResponseResult(result);
        self.resetProjects();
        if (result.kind === 'ok') {
          // if (result.projects) {
          //   result.projects.forEach((item: IProjectSnapshot) => {
          //     // item.conference_date =
          //     // moment(item.start).dayOfYear() === moment(item.end).dayOfYear()
          //     //   ? moment(item.start).format('YYYY-MM-DD HH:mm') +
          //     //     ' ~ ' +
          //     //     moment(item.end).format('HH:mm')
          //     //   : moment(item.start).format('YYYY-MM-DD HH:mm') +
          //     //     ' ~ ' +
          //     //     moment(item.end).format('YYYY-MM-DD HH:mm');
          //   });
          // }
          self.setProjects(result.projects);
          result.pagination && self.setPagination(result.pagination);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    getsForMobile: async (filter?: IProjectFilter) => {
      try {
        const projectApi: ProjectApi = new ProjectApi(self.environment.api);
        const result: TGetProjectsResult = await projectApi.gets(self.paginationForMobile, filter);
        self.rootStore.responseStore.getResponseResult(result);

        if (result.kind === 'ok') {
          self.setAppendProjects(result.projects);
          result.pagination && self.setPaginationForMobile(result.pagination);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 프로젝트 상세 조회
     * @param projectSid
     */
    get: async (projectSid: number) => {
      try {
        const projectApi: ProjectApi = new ProjectApi(self.environment.api);
        const result: TGetProjectResult = await projectApi.get(projectSid);
        self.rootStore.responseStore.getResponseResult(result);

        if (result.kind === 'ok') {
          if (result.project) {
            let classifiedSalesData;

            if (result.project.roundSalesList) {
              classifiedSalesData = result.project.roundSalesList.reduce(
                (roundSales: any, obj: any) => {
                  const code = obj.projectTypeCd.code;
                  const existingItem = roundSales.find((item: any) => item.projectTypeCd === code);
                  if (existingItem) {
                    existingItem.data.push(obj);
                  } else {
                    roundSales.push({ projectTypeCd: code, data: [obj] });
                  }
                  return roundSales;
                },
                [],
              );
            }
            self.setProject({
              ...result.project,
              classifiedRoundSales: classifiedSalesData,
            });
          }

          // self.setProject({ ...result.project });
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 프로젝트 등록
     */
    post: async (project: IProjectSnapshot) => {
      try {
        const projectApi: ProjectApi = new ProjectApi(self.environment.api);
        const result: TPostProjectResult = await projectApi.post(project);
        self.rootStore.responseStore.getResponseResult(result);

        if (result.kind === 'ok') {
          self.setProject(result.project);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 프로젝트 수정
     * @param project
     */
    update: async (project: IProjectSnapshot) => {
      try {
        const projectApi: ProjectApi = new ProjectApi(self.environment.api);
        const result: TUpdateProjectResult = await projectApi.update(project);
        self.rootStore.responseStore.getResponseResult(result);

        if (result.kind === 'ok') {
          self.setProject(result.project);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 프로젝트 삭제
     * @param projectSid
     */
    delete: async (projectSid: number) => {
      try {
        const conferenceApi: ProjectApi = new ProjectApi(self.environment.api);
        const result: TDeleteProjectResult = await conferenceApi.delete(projectSid);
        self.rootStore.responseStore.getResponseResult(result);

        if (result.kind === 'ok') {
          return;
        } else {
          console.error(result);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 캘린더 조회
     * @param filter
     */
    calendar: async (filter?: IProjectFilter) => {
      try {
        const projectApi: ProjectApi = new ProjectApi(self.environment.api);
        const result: TGetCalendarResult = await projectApi.calendar(filter);
        self.rootStore.responseStore.getResponseResult(result);

        if (result.kind === 'ok') {
          self.setCalendarData(result.data);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TProjectStore = Instance<typeof ProjectStore>;
type TProjectStoreSnapshot = SnapshotOut<typeof ProjectStore>;

export interface IProjectStore extends TProjectStore {}
export type TProjectStoreKeys = keyof TProjectStoreSnapshot & string;
export interface IProjectStoreSnapshot extends TProjectStoreSnapshot {}
export const createProjectStore = () => types.optional(ProjectStore, {} as TProjectStore);
