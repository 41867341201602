import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../model-code/ModelCode';

/**
 * # Calendar
 *
 * Calendar을 설명하세요.
 */
export const Calendar = types
  .model('Calendar')
  // --------------------------------------------------------------------------
  .props({
    date: types.optional(types.number, 0),
    type: types.optional(types.string, ''),
    title: types.optional(types.string, ''),
    content: types.maybeNull(types.string),
    projectSid: types.optional(types.number, 0),
    projectTypeCd: types.maybeNull(createModelCode()),
    allDay: types.maybeNull(types.optional(types.boolean, true)),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model calendar --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICalendar;
      (Object.keys(newProps) as TCalendarKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TCalendar = Instance<typeof Calendar>;
type TCalendarSnapshot = SnapshotOut<typeof Calendar>;

export interface ICalendar extends TCalendar {}
export type TCalendarKeys = keyof TCalendarSnapshot & string;
export interface ICalendarSnapshot extends TCalendarSnapshot {}
export const createCalendar = () => types.optional(Calendar, {} as TCalendar);
