import { ApiResponse } from 'apisauce';
import { IPaginationSnapshot } from 'src/models/pagination/Pagination';
import { IResponseSnapshot } from 'src/models/response/Response';

import { ICustomer, ICustomerFilter, ICustomerSnapshot } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import {
  TDeleteCustomerResult,
  TGetCustomerResult,
  TGetCustomersResult,
  TPostCustomerResult,
  TUpdateCustomerResult,
} from './CustomerTypes';

/**
 * # Customer Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new CustomerApi();
 *
 * ```
 */
export class CustomerApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(paging: IPaginationSnapshot, params?: ICustomerFilter): Promise<TGetCustomersResult> {
    try {
      const url = `/v1/customer?page=${paging.page}&size=${paging.size}`;
      // const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const customers: ICustomer[] = response.data.data;
      const pagination: IPaginationSnapshot = response.data.pagination;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', customers, pagination, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(id: number): Promise<TGetCustomerResult> {
    try {
      const url = `/v1/customer/${id}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const customer: ICustomer = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', customer, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(customerData: ICustomerSnapshot): Promise<TPostCustomerResult> {
    try {
      const url = '/v1/customer';
      const payload = customerData;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const customer: ICustomer = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', customer, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(
    customerSid: number,
    customerData: ICustomerSnapshot,
  ): Promise<TUpdateCustomerResult> {
    try {
      const url = `/v1/customer/${customerSid}`;
      const payload = customerData;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const customer: ICustomer = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', customer, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(id: number): Promise<TDeleteCustomerResult> {
    try {
      const url = `/v1/customer/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
