import { useLocation } from 'react-router-dom';
import { Box, CircularProgress, LinearProgress, useTheme } from '@mui/material';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { m } from 'framer-motion';

// auth
import { useAuthContext } from '../../auth/useAuthContext';
// config
import { NAV } from '../../config-global';
// hooks
import useResponsive from '../../hooks/useResponsive';
//
import Logo from '../logo';
import ProgressBar from '../progress-bar';
import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isInitialized } = useAuthContext();

  const { themeLayout } = useSettingsContext();

  const isDashboard = isInitialized && pathname.includes('/dashboard') && isDesktop;

  const theme = useTheme();

  const size =
    (themeLayout === 'mini' && NAV.W_DASHBOARD_MINI) ||
    (themeLayout === 'vertical' && NAV.W_DASHBOARD) ||
    128;

  return (
    <>
      <ProgressBar />

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress sx={{ width: 50, height: 50 }} color={'primary'} />
      </Box>
    </>
  );
}
