import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createRowData } from './RowData';

/**
 * # Government
 *
 * Government을 설명하세요.
 */
export const Government = types
  .model('Government')
  // --------------------------------------------------------------------------
  .props({
    ordr: types.optional(types.number, 0), // 순서
    projectSid: types.maybeNull(types.number), // 프로젝트 아이디 (null : Total)
    projectNm: types.maybeNull(types.optional(types.string, '')), // 프로젝트 이름
    cstmrCmpy: types.maybeNull(types.optional(types.string, '')), // 고객사 (=정부부처)
    data: createRowData(),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model government --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IGovernment;
      (Object.keys(newProps) as TGovernmentKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TGovernment = Instance<typeof Government>;
type TGovernmentSnapshot = SnapshotOut<typeof Government>;

export interface IGovernment extends TGovernment {}
export type TGovernmentKeys = keyof TGovernmentSnapshot & string;
export interface IGovernmentSnapshot extends TGovernmentSnapshot {}
export const createGovernment = () => types.optional(Government, {} as TGovernment);
