// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT = '/';
const ROOT_PATH = path(ROOT, '');

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOT_PATH,
  login: path(ROOT_PATH, 'login'),
  verify: path(ROOT_PATH, 'verify'),
  register: path(ROOT_PATH, 'register'),
  findId: path(ROOT_PATH, 'find-user'),
  newPassword: path(ROOT_PATH, 'new-password'),
  resetPassword: path(ROOT_PATH, 'reset-password'),
  loginUnprotected: path(ROOT_PATH, 'login-unprotected'),
  registerUnprotected: path(ROOT_PATH, 'register-unprotected'),
};

export const PATH_PAGE = {
  page403: '403',
  page404: '404',
  page500: '500',
  comingSoon: 'coming-soon',
  maintenance: 'maintenance',
  faqs: 'faqs',
};

export const PATH_ROOT = {
  root: ROOT_PATH,
  permissionDenied: path(ROOT_PATH, 'permission-denied'),

  fcChart: {
    root: path(ROOT_PATH, 'fc-chart'),
  },

  dashboard: {
    root: path(ROOT_PATH, 'dashboard'),
  },

  user: {
    info: path(ROOT_PATH, 'user/info'),
  },

  project: {
    root: path(ROOT_PATH, 'project'),
  },

  calendar: {
    root: path(ROOT_PATH, 'calendar'),
  },
  crm: {
    root: path(ROOT_PATH, 'crm'),
  },

  myInfo: {
    root: path(ROOT_PATH, 'my/info'),
  },

  management: {
    root: path(ROOT_PATH, 'management'),
    user: path(ROOT_PATH, 'management/user'),
    permission: path(ROOT_PATH, 'management/permission'),
    group: path(ROOT_PATH, 'management/group'),
    menu: path(ROOT_PATH, 'management/menu'),
    setting: path(ROOT_PATH, 'management/setting'),
    authority: {
      root: path(ROOT_PATH, 'authority'),
      group: path(ROOT_PATH, 'authority/group'),
      menu: path(ROOT_PATH, 'authority/menu'),
    },
  },
};
