import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import {
  CustomerApi,
  TDeleteCustomerResult,
  TGetCustomerResult,
  TGetCustomersResult,
  TPostCustomerResult,
  TUpdateCustomerResult,
} from '../../services';
import {
  createCustomer,
  Customer,
  ICustomer,
  ICustomerFilter,
  ICustomerSnapshot,
} from '../customer/Customer';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';

/**
 * # CustomerStore
 *
 * CustomerStore을 설명하세요.
 */
export const CustomerStore = types
  .model('CustomerStore')
  // --------------------------------------------------------------------------
  .props({
    pagination: createPagination(),
    customers: types.optional(types.array(Customer), []),
    customer: createCustomer(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    resetPagination: () => {
      self.pagination = { size: 25, page: 1 } as IPagination;
    },
    setCustomers: (customers: ICustomer[]) => {
      self.customers.replace(customers);
    },
    setCustomer: (customer: ICustomerSnapshot) => {
      self.customer = customer as ICustomer;
    },
    resetCustomer: () => {
      self.customer = {} as ICustomer;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Customers를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async (filter?: ICustomerFilter) => {
      const customerApi: CustomerApi = new CustomerApi(self.environment.api);
      const result: TGetCustomersResult = await customerApi.gets(self.pagination, filter);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setCustomers(result.customers);
        result.pagination && self.setPagination(result.pagination);
      } else {
        console.error(result.kind);
      }
    },

    appendCustomers: async (filter?: ICustomerFilter) => {
      try {
        const customerApi: CustomerApi = new CustomerApi(self.environment.api);
        const result: TGetCustomersResult = await customerApi.gets(self.pagination, filter);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            self.setCustomers(self.customers.concat(result.customers));
            result.pagination && self.setPagination(result.pagination);
          }
        }
      } catch (error) {
        self.rootStore.responseStore.errorProcessing(error);
      }
    },

    get: async (customerSid: number) => {
      const customerApi: CustomerApi = new CustomerApi(self.environment.api);
      const result: TGetCustomerResult = await customerApi.get(customerSid);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setCustomer(result.customer);
      } else {
        console.error(result.kind);
      }
    },

    create: async (customer: ICustomerSnapshot) => {
      const customerApi: CustomerApi = new CustomerApi(self.environment.api);
      const result: TPostCustomerResult = await customerApi.post(customer);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setCustomer(result.customer);
      } else {
        console.error(result.kind);
      }
    },

    update: async (customerSid: number, customer: ICustomerSnapshot) => {
      const customerApi: CustomerApi = new CustomerApi(self.environment.api);
      const result: TUpdateCustomerResult = await customerApi.update(customerSid, customer);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setCustomer(result.customer);
      } else {
        console.error(result.kind);
      }
    },

    delete: async (customerSid: number) => {
      const customerApi: CustomerApi = new CustomerApi(self.environment.api);
      const result: TDeleteCustomerResult = await customerApi.delete(customerSid);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        // self.setCustomer(result.customer);
      } else {
        console.error(result.kind);
      }
    },
  }));

// --------------------------------------------------------------------------
type TCustomerStore = Instance<typeof CustomerStore>;
type TCustomerStoreSnapshot = SnapshotOut<typeof CustomerStore>;

export interface ICustomerStore extends TCustomerStore {}
export type TCustomerStoreKeys = keyof TCustomerStoreSnapshot & string;
export interface ICustomerStoreSnapshot extends TCustomerStoreSnapshot {}
export const createCustomerStore = () => types.optional(CustomerStore, {} as TCustomerStore);
