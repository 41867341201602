import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../../model-code/ModelCode';
import { SfData } from '../sf-data/SfData';

/**
 * # YqData
 *
 * YqData을 설명하세요.
 */
export const YqData = types
  .model('YqData')
  // --------------------------------------------------------------------------
  .props({
    name: types.maybeNull(createModelCode()),
    data: types.maybeNull(types.optional(types.array(SfData), [])),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model yqData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IYqData;
      (Object.keys(newProps) as TYqDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TYqData = Instance<typeof YqData>;
type TYqDataSnapshot = SnapshotOut<typeof YqData>;

export interface IYqData extends TYqData {}
export type TYqDataKeys = keyof TYqDataSnapshot & string;
export interface IYqDataSnapshot extends TYqDataSnapshot {}
export const createYqData = () => types.optional(YqData, {} as TYqData);
