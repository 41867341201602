import { toJS } from 'mobx';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { PermissionApi, TGetPermissionsResult, TUpdatePermissionResult } from 'src/services';

import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createPagination } from '../pagination/Pagination';
import { IPermission, Permission, TPermissionQueryParams } from '../permission/Permission';

/**
 * # PermissionStore
 *
 * PermissionStore을 설명하세요.
 */
export const PermissionStore = types
  .model('PermissionStore')
  // --------------------------------------------------------------------------
  .props({
    pagination: createPagination(),
    permissions: types.optional(types.array(Permission), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({
    get paginationOptions() {
      return toJS(self.pagination);
    },
    get getPermissions() {
      return toJS(self.permissions);
    },
  }))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * permissions을 교체
     *
     * @param `permissions` 새로운 모델의 배열
     */
    setPermissions: (permissions: IPermission[]) => {
      self.permissions.replace(permissions);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Permissions를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async (queryParams?: TPermissionQueryParams) => {
      const permissionApi: PermissionApi = new PermissionApi(self.environment.api);
      const result: TGetPermissionsResult = await permissionApi.gets(queryParams);
      if (result.kind === 'ok') {
        self.setPermissions(result.permissions);
      } else {
        console.error(result.kind);
      }
    },
    update: async (permissions: IPermission[]) => {
      const permissionApi: PermissionApi = new PermissionApi(self.environment.api);
      const result: TUpdatePermissionResult = await permissionApi.update(permissions);
      if (result.kind !== 'ok') {
        console.error(result.kind);
      }
    },
  }));

// --------------------------------------------------------------------------
type TPermissionStore = Instance<typeof PermissionStore>;
type TPermissionStoreSnapshot = SnapshotOut<typeof PermissionStore>;

export interface IPermissionStore extends TPermissionStore {}
export type TPermissionStoreKeys = keyof TPermissionStoreSnapshot & string;
export interface IPermissionStoreSnapshot extends TPermissionStoreSnapshot {}
export const createPermissionStore = () => types.optional(PermissionStore, {} as TPermissionStore);
