import { ApiResponse } from 'apisauce';
import { IModelCodeSnapshot } from 'src/models/model-code/ModelCode';
import { IResponseSnapshot } from 'src/models/response/Response';

import { IFcChart } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import { TCloseFcChartResult, TGetFcChartResult, TGetFinalYearsResult } from './FcChartTypes';

/**
 * # Dashboard Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new FcChartApi();
 *
 * ```
 */
export class FcChartApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  /**
   * FC대시보드 조회
   * @param fcDate
   * @returns
   */
  async get(fcDate: number): Promise<TGetFcChartResult> {
    try {
      const url = `/v1/dashboard/forecast?fcDate=${fcDate}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const dashboard: IFcChart = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', dashboard, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
  /**
   * FC Final대시보드 조회
   * @param fcDate
   * @returns
   */
  async getFinal(fcDate: number): Promise<TGetFcChartResult> {
    try {
      const url = `/v1/dashboard/forecast/final?fcDate=${fcDate}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const dashboard: IFcChart = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', dashboard, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
  /**
   * Final 마감 년도 조회
   * @returns
   */
  async getFinalYears(): Promise<TGetFinalYearsResult> {
    try {
      const url = `/v1/dashboard/forecast/final/years`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const years: IModelCodeSnapshot[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', years, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
  /**
   * 당월 마감
   * @returns
   */
  async close(): Promise<TCloseFcChartResult> {
    try {
      const url = `/v1/dashboard/forecast`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
  /**
   * final 마감
   * @returns
   */
  async finalClose(): Promise<TCloseFcChartResult> {
    try {
      const url = `/v1/dashboard/forecast/final`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
