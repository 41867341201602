import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const DataPermissionList = () =>
  types.model('DataPermissionList').props({
    accessPos: types.optional(types.string, 'mng'),
    menuNm: types.string,
    menuSid: types.number,
  });

export const DataPermission = types
  .model('DataPermission')
  .props({
    empPosNm: types.optional(types.string, ''),
    empPosSid: types.optional(types.number, 0),
    permissionList: types.optional(types.array(DataPermissionList()), []),
  })
  .actions(() => ({}));

type TDataPermission = Instance<typeof DataPermission>;
type TDataPermissionSnapshot = SnapshotOut<typeof DataPermission>;

export interface IDataPermission extends TDataPermission {}
export type TDataPermissionKeys = keyof TDataPermissionSnapshot & string;
export interface IDataPermissionSnapshot extends TDataPermissionSnapshot {}
export const createDataPermission = () => types.optional(DataPermission, {} as TDataPermission);
