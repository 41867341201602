import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../user/SimpleUser';

/**
 * # SysMenu
 *
 * SysMenu을 설명하세요.
 */
export const SysMenu = types
  .model('SysMenu')
  // --------------------------------------------------------------------------
  .props({
    menuSid: types.optional(types.number, 0),
    menuNm: types.optional(types.string, ''),
    menuUri: types.optional(types.string, ''),
    menuPsid: types.maybeNull(types.number),
    ordr: types.maybeNull(types.number),
    dscrpt: types.maybeNull(types.string),
    menuTypeCd: types.maybeNull(createModelCode()),
    useYn: types.optional(types.boolean, true),
    userGroupSid: types.maybeNull(types.number),

    regUser: createSimpleUser(),
    updUser: createSimpleUser(),

    regDt: types.maybeNull(types.optional(types.number, 0)),
    updDt: types.maybeNull(types.optional(types.number, 0)),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model sysMenu --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISysMenu;
      (Object.keys(newProps) as TSysMenuKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSysMenu = Instance<typeof SysMenu>;
type TSysMenuSnapshot = SnapshotOut<typeof SysMenu>;

export interface ISysMenu extends TSysMenu {}
export type TSysMenuKeys = keyof TSysMenuSnapshot & string;
export interface ISysMenuSnapshot extends TSysMenuSnapshot {}
export const createSysMenu = () => types.optional(SysMenu, {} as TSysMenu);
