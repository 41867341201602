import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import {
  FcChartApi,
  TCloseFcChartResult,
  TGetFcChartResult,
  TGetFinalYearsResult,
} from '../../services';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createFcChart, IFcChart, IFcChartSnapshot } from '../fc-chart/FcChart';
import { IModelCode, IModelCodeSnapshot, ModelCode } from '../model-code/ModelCode';

/**
 * # FcChartStore
 *
 * FcChartStore을 설명하세요.
 */
export const FcChartStore = types
  .model('FcChartStore')
  // --------------------------------------------------------------------------
  .props({
    fcChart: createFcChart(),
    finalYears: types.optional(types.array(ModelCode), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setFcChart: (fcChart: IFcChartSnapshot) => {
      self.fcChart = fcChart as IFcChart;
    },
    setFinalYears: (years: IModelCodeSnapshot[]) => {
      self.finalYears.replace(years as IModelCode[]);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * FC 대시보드 조회
     * @param fcDate
     */
    get: async (fcDate: number) => {
      const dashboardApi: FcChartApi = new FcChartApi(self.environment.api);
      const result: TGetFcChartResult = await dashboardApi.get(fcDate);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setFcChart(result.dashboard);
      } else {
        console.error(result.kind);
      }
    },
    /**
     * FC 대시보드 조회
     * @param fcDate
     */
    getFinal: async (fcDate: number) => {
      const dashboardApi: FcChartApi = new FcChartApi(self.environment.api);
      const result: TGetFcChartResult = await dashboardApi.getFinal(fcDate);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setFcChart(result.dashboard);
      } else {
        console.error(result.kind);
      }
    },

    /**
     * Final 마감 년도 조회
     */
    getFinalYears: async () => {
      const dashboardApi: FcChartApi = new FcChartApi(self.environment.api);
      const result: TGetFinalYearsResult = await dashboardApi.getFinalYears();
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setFinalYears(result.years);
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 당월 마감
     */
    close: async () => {
      const dashboardApi: FcChartApi = new FcChartApi(self.environment.api);
      const result: TCloseFcChartResult = await dashboardApi.close();
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        return;
      } else {
        console.error(result.kind);
      }
    },
    /**
     * final 마감
     */
    finalClose: async () => {
      const dashboardApi: FcChartApi = new FcChartApi(self.environment.api);
      const result: TCloseFcChartResult = await dashboardApi.finalClose();
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        return;
      } else {
        console.error(result.kind);
      }
    },
  }));

// --------------------------------------------------------------------------
type TFcChartStore = Instance<typeof FcChartStore>;
type TFcChartStoreSnapshot = SnapshotOut<typeof FcChartStore>;

export interface IFcChartStore extends TFcChartStore {}
export type TFcChartStoreKeys = keyof TFcChartStoreSnapshot & string;
export interface IFcChartStoreSnapshot extends TFcChartStoreSnapshot {}
export const createFcChartStore = () => types.optional(FcChartStore, {} as TFcChartStore);
