import { ApiResponse } from 'apisauce';
import { IResponse } from 'src/models/response/Response';

import { ISysMenu, ISysMenuSnapshot } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import {
  TDeleteSysMenuResult,
  TGetSysMenuResult,
  TGetSysMenusResult,
  TPostSysMenuResult,
  TUpdateSysMenuResult,
} from './SysMenuTypes';

/**
 * # SysMenu Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new SysMenuApi();
 *
 * ```
 */
export class SysMenuApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(): Promise<TGetSysMenusResult> {
    try {
      const url = '/v1/menu';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponse = response.data;
      const sysMenus: ISysMenu[] = response.data.data;
      return { kind: 'ok', sysMenus, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(menuSid: number): Promise<TGetSysMenuResult> {
    try {
      const url = `/v1/menu/${menuSid}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponse = response.data;
      const sysMenu: ISysMenu = response.data.data;
      return { kind: 'ok', sysMenu, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(data: ISysMenuSnapshot): Promise<TPostSysMenuResult> {
    try {
      const url = '/v1/menu';
      const payload = data;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponse = response.data;
      const sysMenu: ISysMenu = response.data.data;
      return { kind: 'ok', sysMenu, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(data: ISysMenuSnapshot): Promise<TUpdateSysMenuResult> {
    try {
      const url = `/v1/menu/${data.menuSid}`;
      const payload = data;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponse = response.data;
      const sysMenu: ISysMenu = response.data.data;
      return { kind: 'ok', sysMenu, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(id: number): Promise<TDeleteSysMenuResult> {
    try {
      const url = `/v1/menu/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponse = response.data;
      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
