import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import {
  SystemSettingApi,
  TGetDynamicCdResult,
  TGetSysVarsResult,
  TUpdateDynamicCdResult,
  TUpdateSysVarsResult,
} from 'src/services';

import { DynamicCode, IDynamicCode, IDynamicCodeSnapshot } from '../dynamic-code/DynamicCode';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { ISysVar, ISysVarSnapshot, SysVar } from '../sys-var/SysVar';

/**
 * # SystemSettingStore
 *
 * SystemSettingStore을 설명하세요.
 */
export const SystemSettingStore = types
  .model('SystemSettingStore')
  // --------------------------------------------------------------------------
  .props({
    sysVars: types.optional(types.array(SysVar), []),
    dynamicCodesForSetting: types.optional(types.array(DynamicCode), []),
    divCd: types.optional(types.array(DynamicCode), []),
    currCd: types.optional(types.array(DynamicCode), []),
    projCd: types.optional(types.array(DynamicCode), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setSysVars: (sysVars: ISysVar[]) => {
      self.sysVars.replace(sysVars);
    },
    setDynamicCodesForSetting: (dynamicCodes: IDynamicCode[]) => {
      self.dynamicCodesForSetting.replace(dynamicCodes);
    },
    setDivCd: (dynamicCodes: IDynamicCode[]) => {
      self.divCd.replace(dynamicCodes);
    },
    setCurrCd: (dynamicCodes: IDynamicCode[]) => {
      self.currCd.replace(dynamicCodes);
    },
    setProjCd: (dynamicCodes: IDynamicCode[]) => {
      self.projCd.replace(dynamicCodes);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 동적코드 항목 조회 (설정화면)
     */
    getDynamicCdForSetting: async (dynamicCd?: number) => {
      const systemSettingApi: SystemSettingApi = new SystemSettingApi(self.environment.api);
      const result: TGetDynamicCdResult = await systemSettingApi.getDynamicCdForSetting(dynamicCd);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        if (dynamicCd === 100001) {
          self.setDivCd(result.codes);
        } else if (dynamicCd === 100002) {
          self.setCurrCd(result.codes);
        } else if (dynamicCd === 100003) {
          self.setProjCd(result.codes);
        } else {
          self.setDynamicCodesForSetting(result.codes);
        }
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 동적코드 항목 저장 (설정화면)
     */
    updateDynamic: async (dynamicCode: number, dynamicCd: IDynamicCodeSnapshot[]) => {
      const systemSettingApi: SystemSettingApi = new SystemSettingApi(self.environment.api);
      const result: TUpdateDynamicCdResult = await systemSettingApi.update(dynamicCode, dynamicCd);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setDynamicCodesForSetting(result.codes);
      } else {
        console.error(result.kind);
      }
    },

    getVars: async () => {
      try {
        const systemSettingApi: SystemSettingApi = new SystemSettingApi(self.environment.api);
        const result: TGetSysVarsResult = await systemSettingApi.getVars();
        self.rootStore.responseStore.getResponseResult(result);
        if (result.kind === 'ok') {
          self.setSysVars(result.sysVars);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        console.error(e);
      }
    },

    updateVars: async (data: ISysVarSnapshot) => {
      try {
        const systemSettingApi: SystemSettingApi = new SystemSettingApi(self.environment.api);
        const result: TUpdateSysVarsResult = await systemSettingApi.updateVars(data);
        self.rootStore.responseStore.getResponseResult(result);
        if (result.kind === 'ok') {
          // self.setSysVars(result.sysVars);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        console.error(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TSystemSettingStore = Instance<typeof SystemSettingStore>;
type TSystemSettingStoreSnapshot = SnapshotOut<typeof SystemSettingStore>;

export interface ISystemSettingStore extends TSystemSettingStore {}
export type TSystemSettingStoreKeys = keyof TSystemSettingStoreSnapshot & string;
export interface ISystemSettingStoreSnapshot extends TSystemSettingStoreSnapshot {}
export const createSystemSettingStore = () =>
  types.optional(SystemSettingStore, {} as TSystemSettingStore);
