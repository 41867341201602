import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps, Link, Typography } from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';
import useResponsive from 'src/hooks/useResponsive';

import { useSettingsContext } from '../settings/SettingsContext';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    const isDesktop = useResponsive('up', 'lg');

    const { themeLayout } = useSettingsContext();

    const isNavMini = themeLayout === 'mini';

    const logoType = other.itemType;

    const isLight = theme.palette.mode === 'light';

    const logo = !(logoType === 'mini') ? (
      <Box
        component="img"
        src={`/assets/images/logo${isLight ? '-color' : ''}.svg`}
        sx={{ width: 100, cursor: 'pointer', ...sx }}
      />
    ) : (
      <Box
        component="img"
        src="/assets/images/logo-only.svg"
        sx={{ width: 50, height: 50, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  },
);

export default Logo;
