import { ApiResponse } from 'apisauce';
import { IPaginationSnapshot } from 'src/models/pagination/Pagination';
import { IResponse } from 'src/models/response/Response';

import { IUserGroup, IUserGroupFilter, IUserGroupSnapshot } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import {
  TDeleteUserGroupResult,
  TGetUserGroupResult,
  TGetUserGroupsMenuResult,
  TGetUserGroupsResult,
  TPostUserGroupResult,
  TUpdateUserGroupResult,
  TUpdateUserGroupsMenuResult,
} from './UserGroupTypes';

/**
 * # UserGroup Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new UserGroupApi();
 *
 * ```
 */
export class UserGroupApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(
    paging: IPaginationSnapshot,
    params?: IUserGroupFilter,
  ): Promise<TGetUserGroupsResult> {
    try {
      let url;

      if (params) {
        // @ts-ignore
        const queryParams = new URLSearchParams(params).toString();
        url = `/v1/userGroup?page=${paging.page}&size=${paging.size}&${queryParams}`;
      } else {
        url = `/v1/userGroup?page=${paging.page}&size=${paging.size}`;
      }

      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const userGroups: IUserGroup[] = response.data.data;
      const pagination: IPaginationSnapshot = response.data.pagination;
      const responseInfo: IResponse = response.data;
      return { kind: 'ok', userGroups, pagination, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 그룹의 메뉴 조회
   * @param userGroupSid
   * @returns
   */
  async userGroupMenuRel(userGroupSid: number): Promise<TGetUserGroupsMenuResult> {
    try {
      const url = `/v1/userGroupMenuRel/userGroup/${userGroupSid}`;

      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const userGroups: IUserGroup[] = response.data.data;
      const responseInfo: IResponse = response.data;
      return { kind: 'ok', userGroups, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 그룹의 메뉴 설정
   * @param userGroupSid
   * @returns
   */
  async updateUserGroupMenu(
    userGroupSid: number,
    menuSidList: number[],
  ): Promise<TUpdateUserGroupsMenuResult> {
    try {
      const url = `/v2/user/userGroupMenu/${userGroupSid}`;
      const params = menuSidList;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const userGroups: IUserGroup[] = response.data.data;
      const responseInfo: IResponse = response.data;
      return { kind: 'ok', userGroups, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(userGroupSid: number): Promise<TGetUserGroupResult> {
    try {
      const url = `/v1/userGroup/${userGroupSid}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponse = response.data;
      const userGroup: IUserGroup = response.data.data;
      return { kind: 'ok', userGroup, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(data: IUserGroupSnapshot): Promise<TPostUserGroupResult> {
    try {
      const url = '/v1/userGroup';
      const payload = data;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponse = response.data;
      const userGroup: IUserGroup = response.data.data;
      return { kind: 'ok', userGroup, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(data: IUserGroupSnapshot): Promise<TUpdateUserGroupResult> {
    try {
      const url = `/v2/userGroup/${data.userGroupSid}`;
      const payload = data;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponse = response.data;
      const userGroup: IUserGroup = response.data.data;
      return { kind: 'ok', userGroup, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(id: number): Promise<TDeleteUserGroupResult> {
    try {
      const url = `/v2/userGroup/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const responseInfo: IResponse = response.data;
      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
