import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { DashboardSp } from '../DashboardSp';

/**
 * # Speed
 *
 * Speed을 설명하세요.
 */
export const Speed = types
  .model('Speed')
  // --------------------------------------------------------------------------
  .props({
    m1: types.optional(types.array(DashboardSp), []),
    m2: types.optional(types.array(DashboardSp), []),
    m3: types.optional(types.array(DashboardSp), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model speed --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISpeed;
      (Object.keys(newProps) as TSpeedKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSpeed = Instance<typeof Speed>;
type TSpeedSnapshot = SnapshotOut<typeof Speed>;

export interface ISpeed extends TSpeed {}
export type TSpeedKeys = keyof TSpeedSnapshot & string;
export interface ISpeedSnapshot extends TSpeedSnapshot {}
export const createSpeed = () => types.optional(Speed, {} as TSpeed);
