import { toJS } from 'mobx';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { DashboardApi, TGetFsResult, TGetSpeedResult, TGetWeightResult } from 'src/services';

import { createFailSuccess, IFailSuccess } from '../dashboard/fail-success/FailSuccess';
import { createSpeed, ISpeed } from '../dashboard/speed/Speed';
import { createWeight, IWeight } from '../dashboard/weight/Weight';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';

/**
 * # DashboardStore
 *
 * DashboardStore을 설명하세요.
 */
export const DashboardStore = types
  .model('DashboardStore')
  // --------------------------------------------------------------------------
  .props({
    dashboards: createWeight() || createSpeed(),
    margin: createWeight(),
    weight: createWeight(),
    speed: createSpeed(),
    fs: createFailSuccess(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * dashboards을 교체
     *
     * @param `dashboards` 새로운 모델의 배열
     */
    setDashboards: (dashboards: IWeight | ISpeed | IFailSuccess) => {
      self.dashboards = dashboards as any;
    },
    setMargin: (dashboards: IWeight) => {
      self.margin = dashboards;
    },
    setWeight: (dashboards: IWeight) => {
      self.weight = dashboards;
    },
    setSpeed: (dashboards: ISpeed) => {
      self.speed = dashboards;
    },
    setFs: (dashboards: IFailSuccess) => {
      self.fs = dashboards;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    getMargin: async (type: string, deprtCd?: number) => {
      const dashboardApi: DashboardApi = new DashboardApi(self.environment.api);
      const result: TGetWeightResult = await dashboardApi.getMargin(type, deprtCd);
      self.rootStore.responseStore.getResponseResult(result);

      if (result.kind === 'ok') {
        self.setDashboards(result.dashboards);
        self.setMargin(result.dashboards);
      } else {
        console.error(result.kind);
      }
    },

    getWeight: async (type: string, deprtCd?: number) => {
      const dashboardApi: DashboardApi = new DashboardApi(self.environment.api);
      const result: TGetWeightResult = await dashboardApi.getWeight(type, deprtCd);
      self.rootStore.responseStore.getResponseResult(result);

      if (result.kind === 'ok') {
        self.setDashboards(result.dashboards);

        // self.setWeight({
        //   order: result.dashboards.order,
        //   // @ts-ignore
        //   sales: result.dashboards.sales.concat([result.dashboards.order[1]]), // sales 차트 기대값 추가
        // });
        self.setWeight(result.dashboards);
      } else {
        console.error(result.kind);
      }
    },
    getSpeed: async (quarterNum: number, deprtCd?: number) => {
      const dashboardApi: DashboardApi = new DashboardApi(self.environment.api);
      const result: TGetSpeedResult = await dashboardApi.getSpeed(quarterNum, deprtCd);
      self.rootStore.responseStore.getResponseResult(result);

      if (result.kind === 'ok') {
        self.setDashboards(result.dashboards);
        self.setSpeed(result.dashboards);
      } else {
        console.error(result.kind);
      }
    },
    getFs: async (quarter: number, deprtCd?: number) => {
      const dashboardApi: DashboardApi = new DashboardApi(self.environment.api);
      const result: TGetFsResult = await dashboardApi.getFs(quarter, deprtCd);
      self.rootStore.responseStore.getResponseResult(result);

      if (result.kind === 'ok') {
        self.setDashboards(result.dashboards);
        self.setFs(result.dashboards);
      } else {
        console.error(result.kind);
      }
    },
  }));

// --------------------------------------------------------------------------
type TDashboardStore = Instance<typeof DashboardStore>;
type TDashboardStoreSnapshot = SnapshotOut<typeof DashboardStore>;

export interface IDashboardStore extends TDashboardStore {}
export type TDashboardStoreKeys = keyof TDashboardStoreSnapshot & string;
export interface IDashboardStoreSnapshot extends TDashboardStoreSnapshot {}
export const createDashboardStore = () => types.optional(DashboardStore, {} as TDashboardStore);
