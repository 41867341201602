import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createSimpleUser } from '../user/SimpleUser';

/**
 * # UserGroup
 *
 * UserGroup을 설명하세요.
 */
export const UserGroup = types
  .model('UserGroup')
  // --------------------------------------------------------------------------
  .props({
    userGroupSid: types.optional(types.number, 0),
    userGroupNm: types.optional(types.string, ''),
    userGroupPsid: types.maybeNull(types.number),
    useYn: types.optional(types.boolean, true),
    dscrpt: types.maybeNull(types.string),
    menuSid: types.maybeNull(types.number),
    roleId: types.maybeNull(types.number),
    // childUserGroupList: types.maybeNull(types.optional(types.array(any), [])),
    roleIdList: types.maybeNull(types.string),
    roleList: types.maybeNull(types.string),

    regUser: createSimpleUser(),
    updUser: createSimpleUser(),

    regDt: types.maybeNull(types.optional(types.number, 0)),
    updDt: types.maybeNull(types.optional(types.number, 0)),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model userGroup --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IUserGroup;
      (Object.keys(newProps) as TUserGroupKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TUserGroup = Instance<typeof UserGroup>;
type TUserGroupSnapshot = SnapshotOut<typeof UserGroup>;

export interface IUserGroup extends TUserGroup {}
export type TUserGroupKeys = keyof TUserGroupSnapshot & string;
export interface IUserGroupSnapshot extends TUserGroupSnapshot {}
export const createUserGroup = () => types.optional(UserGroup, {} as TUserGroup);

export interface IUserGroupFilter {
  userGroupNm?: string;
  dscrpt?: string;
  page?: number;
  size?: number;
}

export type TUserGroupFilter = IUserGroupFilter;
