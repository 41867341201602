import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # WeightData
 *
 * WeightData을 설명하세요.
 */
export const WeightData = types
  .model('WeightData')
  // --------------------------------------------------------------------------
  .props({
    name: types.maybeNull(types.string),
    amt: types.maybeNull(types.optional(types.number, 0)),
    tooltip: types.maybeNull(types.array(types.string)),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model weightData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IWeightData;
      (Object.keys(newProps) as TWeightDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TWeightData = Instance<typeof WeightData>;
type TWeightDataSnapshot = SnapshotOut<typeof WeightData>;

export interface IWeightData extends TWeightData {}
export type TWeightDataKeys = keyof TWeightDataSnapshot & string;
export interface IWeightDataSnapshot extends TWeightDataSnapshot {}
export const createWeightData = () => types.optional(WeightData, {} as TWeightData);
