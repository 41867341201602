import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../model-code/ModelCode';

import { RowListOrdrSales } from './RowList';

/**
 * # OrderSales
 *
 * OrderSales을 설명하세요.
 */
export const OrderSales = types
  .model('OrderSales')
  // --------------------------------------------------------------------------
  .props({
    ordr: types.maybeNull(types.number), // 순서
    deprtCd: types.maybeNull(createModelCode()), // 본부 코드 (null : Total) <동적코드-100001>
    rowList: types.optional(types.array(RowListOrdrSales), []), // row
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model orderSales --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IOrderSales;
      (Object.keys(newProps) as TOrderSalesKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TOrderSales = Instance<typeof OrderSales>;
type TOrderSalesSnapshot = SnapshotOut<typeof OrderSales>;

export interface IOrderSales extends TOrderSales {}
export type TOrderSalesKeys = keyof TOrderSalesSnapshot & string;
export interface IOrderSalesSnapshot extends TOrderSalesSnapshot {}
export const createOrderSales = () => types.optional(OrderSales, {} as TOrderSales);
