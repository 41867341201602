import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/useAuthContext';
import { IPermissionListSnapshotIn, useStores } from 'src/models';

const PermissionContext = createContext<IPermissionListSnapshotIn[] | undefined>(undefined);

const defaultValue: IPermissionListSnapshotIn[] = [];

export const PermissionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [permissions, setPermissions] = useState<IPermissionListSnapshotIn[]>(defaultValue);
  const authContext = useAuthContext();
  const { permissionStore } = useStores();

  const getPermissions = async () => {
    try {
      // 로그인한 유저가 있을 때
      if (authContext.user) {
        const { empPosSid } = authContext.user;
        await permissionStore.gets({ empPosSid });
        const permissionList = permissionStore.getPermissions[0].permissionList;
        setPermissions(permissionList as IPermissionListSnapshotIn[]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <PermissionContext.Provider value={permissions}>{children}</PermissionContext.Provider>;
};

export const usePermissionContext = () => {
  const values = useContext(PermissionContext);
  if (!values) {
    throw new Error('PermissionContext 내부에서 사용해주세요');
  }
  // 네트워크 이슈 등으로 인해 authContext에 유저 정보를 못 불러와 권한 관련 정보를 초기화 못해서 빈 배열을 리턴할 때
  if (values.length === 0) {
    throw new Error('오류가 발생하였습니다. 다시 한번 시도해주세요.');
  }
  return values;
};
