import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../model-code/ModelCode';
import { createUserDefaultModel } from '../user/user';

/**
 * # RoundSalesList
 *
 * RoundSalesList을 설명하세요.
 */
export const RoundSalesList = types
  .model('RoundSalesList')
  // --------------------------------------------------------------------------
  .props({
    round: types.optional(types.number, 0),
    issueDay: types.maybeNull(types.number),
    amt: types.maybeNull(types.number),
    projectSid: types.optional(types.number, 0),
    projectTypeCd: createModelCode(),
    exchgRate: types.maybeNull(types.number),
    currencyCd: types.maybeNull(createModelCode() || types.number),
    regDt: types.optional(types.number, 0),
    updDt: types.optional(types.number, 0),
    regUser: types.maybeNull(createUserDefaultModel()),
    updUser: types.maybeNull(createUserDefaultModel()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model roundSlesList --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRoundSalesList;
      (Object.keys(newProps) as TRoundSalesListKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRoundSalesList = Instance<typeof RoundSalesList>;
type TRoundSalesListSnapshot = SnapshotOut<typeof RoundSalesList>;

export interface IRoundSalesList extends TRoundSalesList {}
export type TRoundSalesListKeys = keyof TRoundSalesListSnapshot & string;
export interface IRoundSalesListSnapshot extends TRoundSalesListSnapshot {}
export const createRoundSalesList = () => types.optional(RoundSalesList, {} as TRoundSalesList);
