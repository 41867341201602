import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { YqData } from 'src/models/dashboard/yq-data/YqData';

import { DashboardSp } from '../DashboardSp';

/**
 * # FailSuccess
 *
 * FailSuccess을 설명하세요.
 */
export const FailSuccess = types
  .model('FailSuccess')
  // --------------------------------------------------------------------------
  .props({
    failQuarter: types.optional(types.array(DashboardSp), []),
    failYear: types.optional(types.array(DashboardSp), []),
    successQuarter: types.optional(types.array(DashboardSp), []),
    successYear: types.optional(types.array(DashboardSp), []),

    failAllMonth: types.optional(types.array(YqData), []),
    failAllQuarter: types.optional(types.array(YqData), []),
    successAllMonth: types.optional(types.array(YqData), []),
    successAllQuarter: types.optional(types.array(YqData), []),

    failPieQuarter: types.optional(types.array(DashboardSp), []),
    successPieQuarter: types.optional(types.array(DashboardSp), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model failSuccess --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IFailSuccess;
      (Object.keys(newProps) as TFailSuccessKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TFailSuccess = Instance<typeof FailSuccess>;
type TFailSuccessSnapshot = SnapshotOut<typeof FailSuccess>;

export interface IFailSuccess extends TFailSuccess {}
export type TFailSuccessKeys = keyof TFailSuccessSnapshot & string;
export interface IFailSuccessSnapshot extends TFailSuccessSnapshot {}
export const createFailSuccess = () => types.optional(FailSuccess, {} as TFailSuccess);
