import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { MyInfoForm } from 'src/screens/user/my-info/MyInfo';
import { UserApi } from 'src/services/user/user-api';
import {
  GetDeleteUserResult,
  GetPostUserResult,
  GetPutUserResult,
  GetUserResult,
  GetUsersResult,
} from 'src/services/user/user-result-types';
import { sendReactNativeMessage } from 'src/utils/common';

import { withEnvironment } from '../../models/extensions/with-environment';
import { createUserDefaultModel, IUser, UserModel } from '../../models/user/user';
import { createPagination, IPagination, IPaginationSnapshot } from '../pagination/Pagination';

import { withRootStore } from './../extensions/with-root-store';
import { IUserSnapshot } from './../user/user';

export const UserStoreModel = types
  .model('UserStore')
  .props({
    pagination: createPagination(),
    users: types.optional(types.array(UserModel), []),
    user: createUserDefaultModel(),
    myInfo: createUserDefaultModel(),
    os: types.optional(types.string, ''),
    order_id: types.optional(types.string, ''),
    verify_info: types.optional(types.string, ''),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setUsers: (users: IUser[]) => {
      self.users.replace(users);
    },
    setUser: (user: IUserSnapshot) => {
      self.user = user as IUser;
    },
    setMyInfo: (user: IUserSnapshot) => {
      self.myInfo = user as IUser;
    },
    resetUsers: () => {
      self.users.replace([]);
    },
    resetUser: () => {
      self.user = {} as IUser;
    },
    setOS: (os: string) => {
      self.os = os;
    },
    setOrderId: (order_id: string) => {
      self.order_id = order_id;
    },
    setVerifyInfo: (jsonstr: string) => {
      self.verify_info = jsonstr;
    },
  }))
  .actions((self) => ({
    // get: async () => {
    //   return self.user;
    // },
    set: async (req: IUserSnapshot) => {
      self.setUser({
        userNm: req.userNm,
      } as IUserSnapshot);
    },
    verifyInfo: (str: string) => {
      self.setVerifyInfo(str);
    },
    setUserName: (name: string) => {
      const user = self.user;
      self.setUser({
        ...user,
        userNm: name,
      });

      sendReactNativeMessage({
        type: 'updateUser',
        payload: {
          userNm: name,
        },
      });
    },
  }))

  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 프로젝트 목록 조회
     * @param filter
     */
    gets: async (filter?: IUserFilter) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetUsersResult = await userApi.gets(self.pagination, filter);
        self.rootStore.responseStore.getResponseResult(result);

        if (result.kind === 'ok') {
          self.resetUsers();
          result.users && self.setUsers(result.users);
          result.pagination && self.setPagination(result.pagination);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    get: async (userSid: number) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetUserResult = await userApi.get(userSid);
        self.rootStore.responseStore.getResponseResult(result);
        self.resetUser();
        if (result.kind === 'ok') {
          const userGroupSidList = result.user?.userGroupList?.map((v) => v.userGroupSid) || null;

          result.user && self.setUser({ ...result.user, userGroupSidList: userGroupSidList });
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    post: async (user: IUserSnapshot) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetPostUserResult = await userApi.post(user);
        self.rootStore.responseStore.getResponseResult(result);
        self.resetUser();
        if (result.kind === 'ok') {
          // const userGroupSidList = result.user?.userGroupList?.map((v) => v.userGroupSid) || null;
          // result.user && self.setUser({ ...result.user, userGroupSidList: userGroupSidList });
          result.user && self.setUser(result.user);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    put: async (user: IUserSnapshot) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetPutUserResult = await userApi.put(user);
        self.rootStore.responseStore.getResponseResult(result);
        self.resetUser();
        if (result.kind === 'ok') {
          // const userGroupSidList = result.user?.userGroupList?.map((v) => v.userGroupSid) || null;
          // result.user && self.setUser({ ...result.user, userGroupSidList: userGroupSidList });
          result.user && self.setUser(result.user);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    delete: async (userSid: number) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetDeleteUserResult = await userApi.delete(userSid);
        self.rootStore.responseStore.getResponseResult(result);
        self.resetUser();
        if (result.kind === 'ok') {
          return;
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    getMyInfo: async () => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetUserResult = await userApi.getMyInfo();
        self.rootStore.responseStore.getResponseResult(result);
        // self.resetUser();
        if (result.kind === 'ok') {
          if (result.user) {
            window.localStorage.setItem('loginUser', JSON.stringify(result.user));
            self.setMyInfo(result.user);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    putMyInfo: async (user: MyInfoForm) => {
      try {
        const userApi: UserApi = new UserApi(self.environment.api);
        const result: GetPutUserResult = await userApi.putMyInfo(user);
        self.rootStore.responseStore.getResponseResult(result);
        if (result.kind === 'ok') {
          result.user && self.setMyInfo(result.user);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------

type UserStoreType = Instance<typeof UserStoreModel>;
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>;
export interface UserStoreSnapshot extends UserStoreSnapshotType {}

export interface IUserFilter {
  userId?: number | null;
  // userToken?: string;
  userNm?: string;
  page?: number;
  size?: number;
}

export type TUserFilter = IUserFilter;
