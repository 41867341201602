import { ApiResponse } from 'apisauce';
import { ICommonCode } from 'src/models';
import { IResponseSnapshot } from 'src/models/response/Response';

import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import { TGetCommonCodesResult, TGetTempPwResult } from './CommonCodeTypes';

/**
 * # CommonCode Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new CommonCodeApi();
 *
 * ```
 */
export class CommonCodeApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getsCommon(): Promise<TGetCommonCodesResult> {
    try {
      const url = '/v1/code';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const codes: ICommonCode[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', codes, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getsDynamic(): Promise<TGetCommonCodesResult> {
    try {
      const url = '/v1/dynamic/code';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const codes: ICommonCode[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', codes, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getTempPw(): Promise<TGetTempPwResult> {
    try {
      const url = `/v1/imsi/pwd`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const data: ICommonCode[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  // async get(id: number): Promise<TGetCommonCodeResult> {
  //   try {
  //     const url = `/code/${id}`;
  //     const params = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }

  //     const commonCode: ICommonCode = response.data.results;
  //     return { kind: 'ok', commonCode };
  //   } catch (e) {
  //     return { kind: 'bad-data' };
  //   }
  // }

  // async post(): Promise<TPostCommonCodeResult> {
  //   try {
  //     const url = '/code';
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }

  //     const commonCode: ICommonCode = response.data.results;
  //     return { kind: 'ok', commonCode };
  //   } catch (e) {
  //     return { kind: 'bad-data' };
  //   }
  // }

  // async update(id: number): Promise<TUpdateCommonCodeResult> {
  //   try {
  //     const url = `/code/${id}`;
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }

  //     const commonCode: ICommonCode = response.data.results;
  //     return { kind: 'ok', commonCode };
  //   } catch (e) {
  //     return { kind: 'bad-data' };
  //   }
  // }

  // async delete(id: number): Promise<TDeleteCommonCodeResult> {
  //   try {
  //     const url = `/code/${id}`;
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }

  //     return { kind: 'ok' };
  //   } catch (e) {
  //     return { kind: 'bad-data' };
  //   }
  // }
}
