import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { ClassifiedRoundSales } from '../classified-round-sales/ClassifiedRoundSales';
import { createCustomer } from '../customer/Customer';
import { createMng } from '../mng/Mng';
import { createModelCode } from '../model-code/ModelCode';
import { createPagination } from '../pagination/Pagination';
import { createUserDefaultModel } from '../user/user';

import { OrderList } from './OrderList';
import { RoundSalesList } from './RoundSalesList';

/**
 * # Project
 *
 * Project을 설명하세요.
 */
export const Project = types
  .model('Project')
  // --------------------------------------------------------------------------
  .props({
    projectSid: types.optional(types.number, 0), // 프로젝트 아이디
    projectMngId: types.maybeNull(types.string), // 프로젝트 관리 아이디
    projectTypeCd: createModelCode() || types.number, // 프로젝트 유형 <코드-2001>
    projectTypeCdList: types.maybeNull(types.optional(types.array(createModelCode()), [])),
    mngList: types.maybeNull(types.optional(types.array(createMng()), [])),
    govtYn: types.optional(types.boolean, false), //
    projectNm: types.optional(types.string, ''), // 프로젝트 이름
    deprtCd: createModelCode(), // 본부 코드 <동적코드-100001>
    severityCd: createModelCode(), // 심각도 코드 <코드-2002>
    reportYn: types.optional(types.boolean, true), //
    progYn: types.optional(types.boolean, false), //
    bo: types.maybeNull(types.string), // BO
    pm: types.maybeNull(types.string), // PM
    customerCmpy: types.maybeNull(createCustomer()), // types.maybeNull(types.string), // 고객사
    cstmrCmpy: types.maybeNull(types.string), // 고객사
    cstmrRelMngNm: types.maybeNull(types.string), // 고객사 담당자 이름
    cstmrRelMngPh: types.maybeNull(types.string), // 고객사 담당자 연락처
    cstmrRelMngEmail: types.maybeNull(types.string), // 고객사 담당자 이메일
    contractCmpy: types.maybeNull(createCustomer()), // types.maybeNull(types.string), // 계약사
    cntrctCmpy: types.maybeNull(types.string), // 계약사
    cntrctDay: types.maybeNull(types.number), //
    startDay: types.maybeNull(types.number), //
    endDay: types.maybeNull(types.number), //
    bzStepCd: types.maybeNull(createModelCode() || types.number), // 영업단계 <코드-2003>
    orderPrcnt: types.maybeNull(types.number), // 수주확률
    orderAmt: types.maybeNull(types.number), // 수주금액
    orderMargin: types.maybeNull(types.number), // 예상수주마진
    marginPrcnt: types.maybeNull(types.number), // 예상수주마진
    orderDay: types.maybeNull(types.number), // 수주일
    salesAmt: types.maybeNull(types.number), // 매출금액
    salesMargin: types.maybeNull(types.number), // 예상매출마진
    orderFCauses: types.maybeNull(createModelCode() || types.number), // 실주 원인
    orderSCauses: types.maybeNull(createModelCode() || types.number), // 수주 원인
    orderList: types.maybeNull(types.array(OrderList)), //
    // classifiedOrder: types.maybeNull(createOrderList()), // 프로젝트 타입별 수주
    roundSalesList: types.maybeNull(types.array(RoundSalesList)), //
    classifiedRoundSales: types.maybeNull(types.array(ClassifiedRoundSales)), // 프로젝트 타입별 매출
    summary: types.maybeNull(types.string), // 요약(상황설명)
    descr: types.maybeNull(types.string), //
    regDt: types.optional(types.number, 0), //
    updDt: types.optional(types.number, 0), //
    regUser: createUserDefaultModel(), //
    updUser: createUserDefaultModel(), //

    pagination: createPagination(), // 페이지 정보

    // Data Grid 표시용
    firstPayment: types.maybeNull(types.number), // 선급금
    firstIssueDay: types.maybeNull(types.number), // 선급금 발행일
    // firstExchgRate: types.maybeNull(types.number), //
    // firstCurrencyCd: types.maybeNull(createModelCode() || types.number), //
    middlePayment: types.maybeNull(types.number), // 중도금
    middleIssueDay: types.maybeNull(types.number), // 중도금 발행일
    // middleExchgRate: types.maybeNull(types.number), //
    // middleCurrencyCd: types.maybeNull(createModelCode() || types.number), //
    lastPayment: types.maybeNull(types.number), // 잔금
    lastIssueDay: types.maybeNull(types.number), // 잔금 발행일
    // lastExchgRate: types.maybeNull(types.number), //
    // lastCurrencyCd: types.maybeNull(createModelCode() || types.number), //
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model project --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IProject;
      (Object.keys(newProps) as TProjectKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TProject = Instance<typeof Project>;
type TProjectSnapshot = SnapshotOut<typeof Project>;

export interface IProject extends TProject {}
export type TProjectKeys = keyof TProjectSnapshot & string;
export interface IProjectSnapshot extends TProjectSnapshot {}
export const createProject = () => types.optional(Project, {} as TProject);

export interface IProjectFilter {
  deprtCd?: number;
  bzStepCd?: number;
  sortBy?: string;
  text?: string;
  severityCdList?: string;
  page?: number;
  size?: number;
  searchSidList?: string | number[];
  startDate?: number;
  endDate?: number;
  dateType?: 'order' | 'sales' | 'margin';
  projectTypeCd?: number;
  orderSCauses?: number;
  orderFCauses?: number;
  orderPrcnt?: number;
  minPrice?: number;
  maxPrice?: number;
}

export type TProjectFilter = IProjectFilter;
