import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # YearGoalData
 *
 * YearGoalData을 설명하세요.
 */
export const YearGoalData = types
  .model('YearGoalData')
  // --------------------------------------------------------------------------
  .props({
    month: types.optional(types.number, 0),
    amt: types.maybeNull(types.number),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model yearGoalData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IYearGoalData;
      (Object.keys(newProps) as TYearGoalDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TYearGoalData = Instance<typeof YearGoalData>;
type TYearGoalDataSnapshot = SnapshotOut<typeof YearGoalData>;

export interface IYearGoalData extends TYearGoalData {}
export type TYearGoalDataKeys = keyof TYearGoalDataSnapshot & string;
export interface IYearGoalDataSnapshot extends TYearGoalDataSnapshot {}
export const createYearGoalData = () => types.optional(YearGoalData, {} as TYearGoalData);
