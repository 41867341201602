// import { observer } from 'mobx-react-lite';
import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import axios from 'axios';
import moment from 'moment';

import { useStores } from '../models';
import { IAuthUserSnapshot } from '../models/auth-user/AuthUser';

import { ActionMapType, AuthStateType, AuthUserType, SessionContextType } from './types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

export const AuthContext = createContext<SessionContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const { authStore } = useStores();
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      // if (!window.localStorage.getItem('remember')) {
      // }
      // if (window.localStorage.getItem('remember') === 'true') {
      //   const id = window.localStorage.getItem('id');
      //   const pw = window.localStorage.getItem('pw');
      //   if (!id) {
      //     return;
      //   }
      //   if (!pw) {
      //     return;
      //   }
      // }

      if (!window.localStorage.getItem('loginUser')) {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        return;
      }
      const myInfo: any = JSON.stringify(localStorage.getItem('loginUser')); // await LoginStore.myInfo();

      if (
        myInfo
        // myInfo && myInfo.resultCode === 'S' && myInfo.data && myInfo.data.oprtrSid > 0
      ) {
        // if (myInfo.data) {
        //   if (myInfo.data.menuList) {
        //     const menuList = myInfo.data.menuList;
        //     menuList.map((v) => {
        //       v.regDt = moment(v.regDt).format('YYYY-MM-DD hh:mm:ss');
        //       v.updDt = moment(v.updDt).format('YYYY-MM-DD hh:mm:ss');
        //     });
        //   }
        // }
        const user: IAuthUserSnapshot = {
          userSid: myInfo.userSid,
          userId: myInfo.userId,
          userNm: myInfo.userNm,
          // menuList: myInfo.data.menuList,
        };
        // authStore.goodLoginStatus(user);

        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        // authStore.badLoginStatus();

        window.localStorage.removeItem('loginUser');
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);

      window.localStorage.removeItem('loginUser');
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const { REACT_APP_API_URL } = process.env;
  // // LOGIN
  const login = useCallback(async (email?: string, password?: string) => {
    // await Auth0Client?.loginWithPopup();
    // const isAuthenticated = await Auth0Client?.isAuthenticated();
    // if (isAuthenticated) {
    //   // const user = await auth0Client?.getUser();
    //   // dispatch({
    //   //   type: Types.LOGIN,
    //   //   payload: {
    //   //     user: {
    //   //       ...user,
    //   //       displayName: user?.name,
    //   //       photoURL: user?.picture,
    //   //       role: 'admin',
    //   //     },
    //   //   },
    //   // });
    const response = await axios.post(REACT_APP_API_URL + '/loginProcess', {
      userId: email,
      userPwd: password,
    });
    return response;
    // }
  }, []);

  // LOGOUT
  const logout = () => {
    authStore.logout();
  };

  const memoizedValue = useMemo(
    () => ({
      // isInitialized: authStore.authStatus.isInitialized || state.isInitialized,
      // isAuthenticated: authStore.authStatus.isAuthenticated || state.isAuthenticated,
      user: authStore.getLoginUserStorageData(),
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      // user: state.user,
      method: 'session',
      logout: logout,
    }),
    [
      authStore.authStatus.isAuthenticated,
      authStore.authStatus.isInitialized,
      authStore.getLoginUserStorageData(),
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      logout,
    ],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
