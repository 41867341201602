import { getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree';

import { CommonCodeApi, TGetCommonCodesResult, TGetTempPwResult } from '../../services';
import { CommonCode, ICommonCode } from '../common-code/CommonCode';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';

/**
 * # CommonCodeStore
 *
 * CommonCodeStore을 설명하세요.
 */
export const CommonCodeStore = types
  .model('CommonCodeStore')
  // --------------------------------------------------------------------------
  .props({
    commonCodes: types.optional(types.array(CommonCode), []),
    dynamicCodes: types.optional(types.array(CommonCode), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({
    getCCode() {
      return getSnapshot(self.commonCodes);
    },
    getCCodeByName(name: string) {
      let result: { code: string; value: string }[] = [];

      self.commonCodes.forEach((v) => {
        if (v.name === name) {
          result = getSnapshot(v.list).map((v) => {
            return {
              code: (v.code ?? '').toString(),
              value: v.value ?? '',
            };
          });
        }
      });

      return result;
    },

    getDCode() {
      return getSnapshot(self.dynamicCodes);
    },
    getDCodeByName(name: string) {
      let result: { code: string; value: string }[] = [];

      self.dynamicCodes.forEach((v) => {
        if (v.name === name) {
          result = getSnapshot(v.list).map((v) => {
            return {
              code: (v.code ?? '').toString(),
              value: v.value ?? '',
            };
          });
        }
      });

      return result;
    },

    // getCodeByPCode(pCode: number) {
    //   return this.getCodeByName(PcodeList[pCode.toString()]);
    // },
  }))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * commonCodes을 교체
     *
     * @param `commonCodes` 새로운 모델의 배열
     */
    setCommonCodes: (commonCodes: ICommonCode[]) => {
      self.commonCodes.replace(commonCodes);
    },
    setDynamicCodes: (dynamicCodes: ICommonCode[]) => {
      self.dynamicCodes.replace(dynamicCodes);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 코드 조회
     */
    getsCommon: async () => {
      const commonCodeApi: CommonCodeApi = new CommonCodeApi(self.environment.api);
      const result: TGetCommonCodesResult = await commonCodeApi.getsCommon();
      if (result.kind === 'ok') {
        self.setCommonCodes(result.codes);
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 동적 코드 조회
     */
    getsDynamic: async () => {
      const commonCodeApi: CommonCodeApi = new CommonCodeApi(self.environment.api);
      const result: TGetCommonCodesResult = await commonCodeApi.getsDynamic();
      if (result.kind === 'ok') {
        self.setDynamicCodes(result.codes);
      } else {
        console.error(result.kind);
      }
    },

    getTempPw: async () => {
      const commonCodeApi: CommonCodeApi = new CommonCodeApi(self.environment.api);
      const result: TGetTempPwResult = await commonCodeApi.getTempPw();
      if (result.kind === 'ok') {
        // result.data && self.setCommonCodes(result.data);
      } else {
        console.error(result.kind);
      }
    },
  }));

// --------------------------------------------------------------------------
type TCommonCodeStore = Instance<typeof CommonCodeStore>;
type TCommonCodeStoreSnapshot = SnapshotOut<typeof CommonCodeStore>;

export interface ICommonCodeStore extends TCommonCodeStore {}
export type TCommonCodeStoreKeys = keyof TCommonCodeStoreSnapshot & string;
export interface ICommonCodeStoreSnapshot extends TCommonCodeStoreSnapshot {}
export const createCommonCodeStore = () => types.optional(CommonCodeStore, {} as TCommonCodeStore);
