import './locales/i18n';
import 'simplebar/src/simplebar.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import './utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

// import { AuthProvider } from './auth/JwtContext';
import { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
// ----------------------------------------------------------------------
import { BrowserRouter } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { AuthProvider } from './auth/SessionContext';
import { MotionLazyContainer } from './components/animate';
import { StyledChart } from './components/chart';
import LoadingAPI from './components/LoadingAPI';
import { PermissionContextProvider } from './components/permission-access/PermissionContext';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider, ThemeSettings } from './components/settings';
import SnackbarProvider from './components/snackbar';
import { sendReactNativeMessage } from './utils/common';
import { GlobalDebug } from './utils/globalDebug';
import ThemeLocalization from './locales';
import { RootStore, RootStoreProvider, setupRootStore } from './models';
import Router from './routes';
import ThemeProvider from './theme';
// import { AuthProvider } from './auth/Auth0Context';
// import { AuthProvider } from './auth/FirebaseContext';
// import { AuthProvider } from './auth/AwsCognitoContext';

// ----------------------------------------------------------------------

export default function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  useEffect(() => {
    // mui pro license
    LicenseInfo.setLicenseKey(
      '23b7f0919a945717faf81f758e97f4b3Tz03OTkxNCxFPTE3MzMyMDE0NzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
    );

    (async () => {
      setupRootStore().then((store) => {
        setRootStore(store);
        sendReactNativeMessage({
          type: 'initUser',
          payload: null,
        });
      });
    })();
    if (window.localStorage.getItem('useDebug') !== 'true') {
      process.env.NODE_ENV === 'production' && GlobalDebug(false, false);
    }
  }, []);
  if (!rootStore) {
    return null;
  } else {
    return (
      <RootStoreProvider value={rootStore}>
        <AuthProvider>
          <PermissionContextProvider>
            <HelmetProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                  <BrowserRouter>
                    <ScrollToTop />
                    <MotionLazyContainer>
                      <ThemeProvider>
                        <ThemeLocalization>
                          <SnackbarProvider>
                            <StyledChart />
                            <Router />
                            <LoadingAPI />
                          </SnackbarProvider>
                        </ThemeLocalization>
                      </ThemeProvider>
                    </MotionLazyContainer>
                  </BrowserRouter>
                </SettingsProvider>
              </LocalizationProvider>
            </HelmetProvider>
          </PermissionContextProvider>
        </AuthProvider>
      </RootStoreProvider>
    );
  }
}
