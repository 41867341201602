// @mui
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Box, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { fontWeight } from '@mui/system';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useSettingsContext } from 'src/components/settings';

import { NavItemProps, NavProps } from '../types';

// ----------------------------------------------------------------------

export default function NavDesktop({ isOffset, data, isAdmin }: NavProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { themeMode } = useSettingsContext();
  const { user } = useAuthContext();
  const theme = useTheme();

  const fill = {
    default: themeMode === 'dark' ? '#919EAB' : '#999999',
    active: theme.palette.primary.main,
    // active: themeMode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main,
    // active: themeMode === 'dark' ? '#FFFFFF' : '#000000',
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const ACCESSIBLE_MENU_URI_LIST = user?.menuList.map((m: any) => m.menuUri);

  return (
    <Stack component="nav" direction="row">
      <Stack direction={'row'} spacing={4} sx={{ '& *': { transition: 'all 0.2s' } }}>
        {data
          .filter((r) => {
            // if (isAdmin === true) {
            //   return r.isDesktop === true || r.isAdmin === isAdmin;
            // } else {
            //   return r.isDesktop === true && r.isAdmin === isAdmin;
            // }
            return ACCESSIBLE_MENU_URI_LIST.includes(r.path); // 사용자 메뉴권한에 따라 filtered
          })
          .map((item: NavItemProps, index: number) => {
            return (
              <Stack key={`menu-${index}`} direction={'row'} gap={1}>
                <Box
                  width={'100%'}
                  sx={{ display: 'flex', lineHeight: '1.2', cursor: 'pointer', gap: 1 }}
                  onClick={(e) => {
                    if (item.children) {
                      handleClick(e);
                      // setOpenChildren(!openChildren);
                    } else {
                      navigate(item.path);
                      // setOpenChildren(false);
                    }
                  }}
                >
                  {/* <item.icon fill={pathname === item.path ? fill.active : fill.default} /> */}
                  <Typography
                    variant={'body1'}
                    component={'p'}
                    sx={{
                      color:
                        pathname === '/' && item.path === '/fc-chart'
                          ? fill.active
                          : pathname.includes(item.path)
                          ? fill.active
                          : fill.default,
                      fontWeight:
                        pathname === '/' && item.path === '/fc-chart'
                          ? 700
                          : pathname.includes(item.path)
                          ? 700
                          : 400,
                      wordBreak: 'keep-all',
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>

                <Stack
                  component="nav"
                  sx={{
                    display: 'flex',
                    lineHeight: '1.2',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '80%',
                    // transform: 'translateX(30%)',
                  }}
                >
                  {item.children && open && (
                    <Menu
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          mt: 1.5,
                          transform: 'translateX(20%) !important',
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      {item.children?.map((child, j) => {
                        return (
                          // 사용자 메뉴권한에 따라 filtered
                          ACCESSIBLE_MENU_URI_LIST.includes(child.path) && (
                            <MenuItem
                              key={'child' + j}
                              onClick={() => {
                                navigate(child.path);
                              }}
                            >
                              <Typography
                                color={theme.palette.text.secondary}
                                p={0.5}
                                sx={{
                                  color: pathname.includes(child.path)
                                    ? theme.palette.text.primary
                                    : fill.default,
                                  fontWeight: pathname.includes(child.path) ? 600 : 400,
                                }}
                              >
                                {child.title}
                              </Typography>
                            </MenuItem>
                          )
                        );
                      })}
                    </Menu>
                  )}
                </Stack>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
}
