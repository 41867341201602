import { toJS } from 'mobx';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { SNACKBAR_MSG } from '../../components/settings/constEnum';
import { createErrorAlert, IErrorAlert, IErrorAlertSnapshot } from '../error-alert/ErrorAlert';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';

export const ErrorAlertStore = types
  .model('ErrorAlertStore')
  .props({
    errorAlert: createErrorAlert(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setErrorAlertFromResponse: () => {
      const msg = self.rootStore.responseStore.responseInfo.errorMessage;
      const errorAlert: IErrorAlertSnapshot = {
        open: true,
        content: msg ? msg : SNACKBAR_MSG.common.error,
        contentDetail: toJS(self.rootStore.responseStore.responseInfo.details as string[]),
      };
      self.errorAlert = errorAlert as IErrorAlert;
    },
    resetErrorAlert: () => {
      self.errorAlert.open = false;
      self.errorAlert.content = '';
      self.errorAlert.contentDetail.replace([] as string[]);
    },
  }))
  .actions((self) => ({}));

type TErrorAlertStore = Instance<typeof ErrorAlertStore>;
type TErrorAlertStoreSnapshot = SnapshotOut<typeof ErrorAlertStore>;

export interface IErrorAlertStore extends TErrorAlertStore {}
export type TErrorAlertStoreKeys = keyof TErrorAlertStoreSnapshot & string;
export interface IErrorAlertStoreSnapshot extends TErrorAlertStoreSnapshot {}
export const createErrorAlertStore = () => types.optional(ErrorAlertStore, {} as TErrorAlertStore);
