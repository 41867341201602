import { ApiResponse } from 'apisauce';
import { IDataPermission } from 'src/models/data-permission/DataPermission';

import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import {
  TDeleteDataPermissionResult,
  TGetDataPermissionResult,
  TGetDataPermissionsResult,
  TPostDataPermissionResult,
  TUpdateDataPermissionResult,
} from './DataPermissionTypes';

/**
 * # Permission Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new PermissionApi();
 *
 * ```
 */
export class DataPermissionApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(): Promise<TGetDataPermissionsResult> {
    try {
      const url = '/v1/system/permission';
      const params = '';
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const permissions: IDataPermission[] = response.data.data;
      return { kind: 'ok', permissions };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(): Promise<TPostDataPermissionResult> {
    try {
      const url = '/v1/system/permission';
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const permission: IDataPermission = response.data.results;
      return { kind: 'ok', permission };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(permissions: IDataPermission[]): Promise<TUpdateDataPermissionResult> {
    try {
      const url = `/v1/system/permission`;
      const payload = permissions;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return { kind: 'ok' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(id: number): Promise<TDeleteDataPermissionResult> {
    try {
      const url = `/permission/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return { kind: 'ok' };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
