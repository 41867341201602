import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { YearGoalData } from './YearGoalData';

/**
 * # YearGoal
 *
 * YearGoal을 설명하세요.
 */
export const YearGoal = types
  .model('YearGoal')
  // --------------------------------------------------------------------------
  .props({
    year: types.optional(types.number, 0),

    order: types.optional(types.array(YearGoalData), []),
    sales: types.optional(types.array(YearGoalData), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model yearGoal --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IYearGoal;
      (Object.keys(newProps) as TYearGoalKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TYearGoal = Instance<typeof YearGoal>;
type TYearGoalSnapshot = SnapshotOut<typeof YearGoal>;

export interface IYearGoal extends TYearGoal {}
export type TYearGoalKeys = keyof TYearGoalSnapshot & string;
export interface IYearGoalSnapshot extends TYearGoalSnapshot {}
export const createYearGoal = () => types.optional(YearGoal, {} as TYearGoal);
