import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # LoginResponse
 *
 * LoginResponse을 설명하세요.
 */
export const LoginResponse = types
  .model('LoginResponse')
  // --------------------------------------------------------------------------
  .props({
    loginResult: types.optional(types.string, ''),
    errorMessage: types.optional(types.maybeNull(types.string), ''),
    isOtp: types.optional(types.maybeNull(types.string), ''),
    expired: types.optional(types.boolean, false),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model loginResponse --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ILoginResponse;
      (Object.keys(newProps) as TLoginResponseKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TLoginResponse = Instance<typeof LoginResponse>;
type TLoginResponseSnapshot = SnapshotOut<typeof LoginResponse>;

export interface ILoginResponse extends TLoginResponse {}
export type TLoginResponseKeys = keyof TLoginResponseSnapshot & string;
export interface ILoginResponseSnapshot extends TLoginResponseSnapshot {}
export const createLoginResponse = () =>
  types.optional(LoginResponse, {
    loginResult: '',
    errorMessage: '',
    isOtp: '',
  } as TLoginResponse);
