import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../model-code/ModelCode';
import { createSimpleUser } from '../user/SimpleUser';

/**
 * # SysVar
 *
 * SysVar을 설명하세요.
 */
export const SysVar = types
  .model('SysVar')
  // --------------------------------------------------------------------------
  .props({
    varKey: types.optional(types.string, ''),
    varVal: types.optional(types.string, ''),
    dataTypeCd: createModelCode(),
    description: types.maybeNull(types.string),
    regUser: types.maybeNull(createSimpleUser()),
    updUser: types.maybeNull(createSimpleUser()),
    regDt: types.maybeNull(types.number),
    updDt: types.maybeNull(types.number),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model sysVars --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISysVar;
      (Object.keys(newProps) as TSysVarKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSysVar = Instance<typeof SysVar>;
type TSysVarSnapshot = SnapshotOut<typeof SysVar>;

export interface ISysVar extends TSysVar {}
export type TSysVarKeys = keyof TSysVarSnapshot & string;
export interface ISysVarSnapshot extends TSysVarSnapshot {}
export const createSysVar = () => types.optional(SysVar, {} as TSysVar);
