import { ApisauceInstance, create } from 'apisauce';

import { IResponseSnapshot } from '../../models/response/Response';

import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';

/**
 * Manages all requests to the API.
 */
export class Api {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  // @ts-ignore
  apisauce: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
      },
      withCredentials: this.config.withCredentials,
    });

    // 요청 인터셉터 추가
    this.apisauce.axiosInstance.interceptors.request.use(
      (config) => {
        // Webview에서 요청 시 헤더 Client-Type 추가
        if (window.ReactNativeWebView) {
          config.headers['Client-Type'] = 'APP';
        }

        return config;
      },
      (error) => {
        // 요청 오류 처리를 여기에 작성합니다.
        return Promise.reject(error);
      },
    );
  }
}
