import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { AuthStore, AuthStoreModel } from '../../models/auth-store/auth-store';
import { LanguageStore, LanguageStoreModel } from '../../models/language-store/language-store';
import { UserStore, UserStoreModel } from '../../models/user-store/user-store';
import { CommonCodeStore, ICommonCodeStore } from '../common-code-store/CommonCodeStore';
import { CustomerStore, ICustomerStore } from '../customer-store/CustomerStore';
import { DashboardStore, IDashboardStore } from '../dashboard-store/DashboardStore';
import {
  DataPermissionStore,
  IDataPermissionStore,
} from '../data-permission-store/DataPermissionStore';
import { ErrorAlertStore, IErrorAlertStore } from '../error-alert-store/ErrorAlertStore';
import { FcChartStore, IFcChartStore } from '../fc-chart-store/FcChartStore';
import { ILoadingStore, LoadingStore } from '../loading-store/LoadingStore';
import { IMngStore, MngStore } from '../mng-store/MngStore';
import { IPermissionStore, PermissionStore } from '../permission-store/PermissionStore';
import { IProjectStore, ProjectStore } from '../project-store/ProjectStore';
import { IResponseStore, ResponseStore } from '../response-store/ResponseStore';
import { ISysMenuStore, SysMenuStore } from '../sys-menu-store/SysMenuStore';
import {
  ISystemSettingStore,
  SystemSettingStore,
} from '../system-setting-store/SystemSettingStore';
import { IUserGroupStore, UserGroupStore } from '../user-group-store/UserGroupStore';
import { IYearGoalStore, YearGoalStore } from '../year-goal-store/YearGoalStore';

// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  userStore: types.optional(UserStoreModel, {} as UserStore),
  authStore: types.optional(AuthStoreModel, {} as AuthStore),
  userGroupStore: types.optional(UserGroupStore, {} as IUserGroupStore),
  sysMenuStore: types.optional(SysMenuStore, {} as ISysMenuStore),
  sysSettingStore: types.optional(SystemSettingStore, {} as ISystemSettingStore),
  permissionStore: types.optional(PermissionStore, {} as IPermissionStore),
  dataPermissionStore: types.optional(DataPermissionStore, {} as IDataPermissionStore),

  languageStore: types.optional(LanguageStoreModel, {} as LanguageStore),
  errorAlertStore: types.optional(ErrorAlertStore, {} as IErrorAlertStore),
  responseStore: types.optional(ResponseStore, {} as IResponseStore),
  loadingStore: types.optional(LoadingStore, {} as ILoadingStore),

  commonCodeStore: types.optional(CommonCodeStore, {} as ICommonCodeStore),
  fcChartStore: types.optional(FcChartStore, {} as IFcChartStore),
  dashboardStore: types.optional(DashboardStore, {} as IDashboardStore),
  projectStore: types.optional(ProjectStore, {} as IProjectStore),
  customerStore: types.optional(CustomerStore, {} as ICustomerStore),
  mngStore: types.optional(MngStore, {} as IMngStore),
  yearGoalStore: types.optional(YearGoalStore, {} as IYearGoalStore)
});

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
