import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import {
  SysMenuApi,
  TDeleteSysMenuResult,
  TGetSysMenuResult,
  TGetSysMenusResult,
  TPostSysMenuResult,
  TUpdateSysMenuResult,
} from 'src/services';

import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createSysMenu, ISysMenu, ISysMenuSnapshot, SysMenu } from '../sys-menu/SysMenu';

/**
 * # SysMenuStore
 *
 * SysMenuStore을 설명하세요.
 */
export const SysMenuStore = types
  .model('SysMenuStore')
  // --------------------------------------------------------------------------
  .props({
    sysMenus: types.optional(types.array(SysMenu), []),
    sysMenu: createSysMenu(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * sysMenus을 교체
     *
     * @param `sysMenus` 새로운 모델의 배열
     */
    setSysMenus: (sysMenus: ISysMenu[]) => {
      self.sysMenus.replace(sysMenus);
    },
    resetSysMenus: () => {
      self.sysMenus.replace([]);
    },
    setSysMenu: (sysMenu: ISysMenu) => {
      self.sysMenu = sysMenu as ISysMenu;
    },
    resetSysMenu: () => {
      self.sysMenu = {} as ISysMenu;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 SysMenus를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async () => {
      const sysMenuApi: SysMenuApi = new SysMenuApi(self.environment.api);
      const result: TGetSysMenusResult = await sysMenuApi.gets();
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setSysMenus(result.sysMenus);
      } else {
        console.error(result.kind);
      }
    },

    get: async (sysMenuSid: number) => {
      const sysMenuApi: SysMenuApi = new SysMenuApi(self.environment.api);

      const result: TGetSysMenuResult = await sysMenuApi.get(sysMenuSid);
      self.rootStore.responseStore.getResponseResult(result);
      self.resetSysMenu();
      if (result.kind === 'ok') {
        self.setSysMenu(result.sysMenu);
      } else {
        console.error(result.kind);
      }
    },

    post: async (sysMenu: ISysMenuSnapshot) => {
      try {
        const sysMenuApi: SysMenuApi = new SysMenuApi(self.environment.api);

        const result: TPostSysMenuResult = await sysMenuApi.post(sysMenu);
        self.rootStore.responseStore.getResponseResult(result);
        self.resetSysMenu();
        if (result.kind === 'ok') {
          result.sysMenu && self.setSysMenu(result.sysMenu);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    put: async (sysMenu: ISysMenuSnapshot) => {
      try {
        const sysMenuApi: SysMenuApi = new SysMenuApi(self.environment.api);

        const result: TUpdateSysMenuResult = await sysMenuApi.update(sysMenu);
        self.rootStore.responseStore.getResponseResult(result);
        self.resetSysMenu();
        if (result.kind === 'ok') {
          result.sysMenu && self.setSysMenu(result.sysMenu);
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    delete: async (userSid: number) => {
      try {
        const sysMenuApi: SysMenuApi = new SysMenuApi(self.environment.api);

        const result: TDeleteSysMenuResult = await sysMenuApi.delete(userSid);
        self.rootStore.responseStore.getResponseResult(result);
        self.resetSysMenu();
        if (result.kind === 'ok') {
          return;
        } else {
          console.error(result.kind);
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TSysMenuStore = Instance<typeof SysMenuStore>;
type TSysMenuStoreSnapshot = SnapshotOut<typeof SysMenuStore>;

export interface ISysMenuStore extends TSysMenuStore {}
export type TSysMenuStoreKeys = keyof TSysMenuStoreSnapshot & string;
export interface ISysMenuStoreSnapshot extends TSysMenuStoreSnapshot {}
export const createSysMenuStore = () => types.optional(SysMenuStore, {} as TSysMenuStore);
