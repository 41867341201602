import { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import Logout from '@mui/icons-material/Logout';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import Iconify from 'src/components/iconify/Iconify';
import useResponsive from 'src/hooks/useResponsive';
import { useSettings } from 'src/hooks/useSettings';
import { useStores } from 'src/models';
import { isMobile } from 'src/utils/common';

import { useAuthContext } from '../auth/useAuthContext';
import Logo from '../components/logo';
import { HEADER } from '../config-global';
import useOffSetTop from '../hooks/useOffSetTop';

import NavDesktop from './dashboard/nav/desktop';
import NavVertical from './dashboard/nav/NavVertical';
import navConfig from './dashboard/nav/osfc-config-navigation';
import { NavItemProps } from './dashboard/nav/types';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const auth = useAuthContext();
  const rootStore = useStores();
  const { authStore } = rootStore;
  const { REACT_APP_ADMIN_GROUP_SID } = process.env;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { themeMode, onChangeMode } = useSettings();
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isMd = useResponsive('down', 'md');
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const loginUser = localStorage.loginUser && JSON.parse(localStorage.loginUser);

  const logout = async () => {
    await authStore.logout().then(() => {
      localStorage.removeItem('loginUser');
    });
  };

  const dragRef = useRef<HTMLDivElement>(null);
  const user = JSON.parse(localStorage.loginUser);
  const isAdmin = user?.userGroupList
    ?.map((v: any) => v.userGroupSid)
    .includes(Number(REACT_APP_ADMIN_GROUP_SID));

  const [menuOpen, setMenuOpen] = useState(false);
  let title;
  navConfig.map((item: NavItemProps) => {
    if (pathname === '/') {
      title = 'FC CHART';
    }
    if (pathname === '/my/info') {
      title = '내정보 수정';
    }
    if (pathname === item.path) {
      title = item?.enTitle ? item.enTitle : item.title;
    }
    if (item.children) {
      item.children.map((child) => {
        if (pathname === child.path) {
          title = child?.enTitle ? child.enTitle : child.title;
        }
      });
    }
  });

  return (
    <>
      <AppBar color="default">
        <Toolbar
          disableGutters
          sx={{
            height: {
              xs: HEADER.H_MOBILE,
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
              position: 'relative',
            }}
          >
            {isMobile() || isMd ? (
              <>
                <IconButton onClick={() => setMenuOpen(!menuOpen)}>
                  <Iconify icon={'eva:menu-2-outline'} sx={{ rotate: '180deg' }} />
                </IconButton>
                <Typography variant="h4" fontWeight={800}>
                  {title || ''}
                </Typography>
                <Avatar
                  onClick={handleClick}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  sx={{ cursor: 'pointer', background: 'none' }}
                />
              </>
            ) : (
              <>
                <Logo sx={{ pl: 0 }} />
                <Box
                  ref={dragRef}
                  sx={{
                    mx: 2,
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                      height: 2,
                    },
                    '&::-webkit-scrollbar-thumb:horizontal': {
                      backgroundColor: theme.palette.primary.main,
                      height: 2,
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#EEEEEE',
                    },
                  }}
                >
                  <NavDesktop isOffset={isOffset} data={navConfig} isAdmin={isAdmin} />
                </Box>
                <Stack ml={1} direction={'row'} gap={1}>
                  <IconButton
                    onClick={() => {
                      const val = themeMode === 'light' ? 'dark' : 'light';
                      onChangeMode({
                        target: { value: val },
                      } as unknown as React.ChangeEvent<HTMLInputElement>);
                    }}
                  >
                    {themeMode === 'light' ? (
                      <NightlightOutlinedIcon sx={{ rotate: '-45deg' }} />
                    ) : (
                      <LightModeOutlinedIcon />
                    )}
                  </IconButton>
                  <Stack direction={'row'} gap={1}>
                    <Avatar />
                    <Stack
                      onClick={handleClick}
                      aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Typography>{loginUser.userNm}</Typography>
                      <Typography variant="caption" color={'GrayText'}>
                        {moment(loginUser.lastLoginDt).format('YY/MM/DD HH:mm')} 접속
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {open && ( // 마이페이지 아이콘 popover
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.2))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => navigate('/my/info')}>
            <ListItemIcon>
              {/* <Avatar /> */}
              <Iconify icon={'mingcute:user-setting-line'} />
            </ListItemIcon>
            내정보 수정
          </MenuItem>
          <Divider />

          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            로그아웃
          </MenuItem>
        </Menu>
      )}

      <NavVertical openNav={menuOpen} onCloseNav={() => setMenuOpen(false)} />
    </>
  );
}
