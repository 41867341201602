import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../model-code/ModelCode';

import { createRowData } from './RowData';

/**
 * # RowListOrdrSales
 *
 * RowListOrdrSales을 설명하세요.
 */
export const RowListOrdrSales = types
  .model('RowListOrdrSales')
  // --------------------------------------------------------------------------
  .props({
    ordr: types.maybeNull(types.number), // 순서
    projectTypeCd: types.maybeNull(createModelCode()), // 프로젝트유형 (null : 소계) <코드-2001>
    subTitle: types.maybeNull(types.string),
    data: createRowData(),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model rowList --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRowListOrdrSales;
      (Object.keys(newProps) as TRowListOrdrSalesKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRowListOrdrSales = Instance<typeof RowListOrdrSales>;
type TRowListOrdrSalesSnapshot = SnapshotOut<typeof RowListOrdrSales>;

export interface IRowListOrdrSales extends TRowListOrdrSales {}
export type TRowListOrdrSalesKeys = keyof TRowListOrdrSalesSnapshot & string;
export interface IRowListOrdrSalesSnapshot extends TRowListOrdrSalesSnapshot {}
export const createRowListOrdrSales = () =>
  types.optional(RowListOrdrSales, {} as TRowListOrdrSales);
