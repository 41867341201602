import { ApiResponse } from 'apisauce';
import { IResponseSnapshot } from 'src/models/response/Response';

import { IDynamicCode, IDynamicCodeSnapshot, ISysVar, ISysVarSnapshot } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import {
  TGetDynamicCdResult,
  TGetSysVarsResult,
  TUpdateDynamicCdResult,
  TUpdateSysVarsResult,
} from './SystemSettingTypes';

/**
 * # SystemSetting Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new SystemSettingApi();
 *
 * ```
 */
export class SystemSettingApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getDynamicCdForSetting(dynamicCd?: number): Promise<TGetDynamicCdResult> {
    try {
      const url = `/v1/dynamic/code/item?dynamicCd=${dynamicCd}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const codes: IDynamicCode[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', codes, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(
    dynamicCode: number,
    dynamicCd: IDynamicCodeSnapshot[],
  ): Promise<TUpdateDynamicCdResult> {
    try {
      const url = `/v1/dynamic/code/${dynamicCode}/item`;
      const payload = dynamicCd;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const codes: IDynamicCode[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', codes, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getVars(): Promise<TGetSysVarsResult> {
    try {
      const url = '/v1/sys/global/var';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const sysVars: ISysVar[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', sysVars, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async updateVars(data: ISysVarSnapshot): Promise<TUpdateSysVarsResult> {
    try {
      const url = '/v1/sys/global/var';
      const params = data;
      const response: ApiResponse<any> = await this.api.apisauce.put(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const sysVar: ISysVar = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', sysVar, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  // async get(id:number): Promise<TGetSystemSettingResult> {
  //   try {
  //     const url = `/system_setting/${id}`;
  //     const params = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.get( url, params );

  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }

  //     const systemSetting: ISystemSetting = response.data.results;
  //     return { kind: "ok", systemSetting };

  //   } catch (e) {
  //     return { kind: "bad-data" };
  //   }
  // }

  // async post(): Promise<TPostSystemSettingResult> {
  //   try {
  //     const url = '/system_setting';
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );

  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }

  //     const systemSetting: ISystemSetting = response.data.results;
  //     return { kind: "ok", systemSetting };

  //   } catch (e) {
  //     return { kind: "bad-data" };
  //   }
  // }

  // async update(id:number): Promise<TUpdateSystemSettingResult> {
  //   try {
  //     const url = `/system_setting/${id}`;
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.put( url, payload );

  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }

  //     const systemSetting: ISystemSetting = response.data.results;
  //     return { kind: "ok", systemSetting };

  //   } catch (e) {
  //     return { kind: "bad-data" };
  //   }
  // }

  // async delete(id:number): Promise<TDeleteSystemSettingResult> {
  //   try {
  //     const url = `/system_setting/${id}`;
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.delete( url, payload );

  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }

  //     return { kind: "ok" };

  //   } catch (e) {
  //     return { kind: "bad-data" };
  //   }
  // }
}
