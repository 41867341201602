import { ILoadingStore } from '../models/loading-store/LoadingStore';

export const CallApiToStore = async (
  storeFunction: Promise<void>,
  type: 'page' | 'api',
  loadingStore: ILoadingStore,
) => {
  loadingStore.allLoading();
  await storeFunction.then(() => {
    loadingStore.closeLoadingNoMsg();
  });
};

interface Message {
  type: string;
  payload?: any;
}

export const sendReactNativeMessage = ({ type, payload }: Message) => {
  // @ts-ignore
  if (window.ReactNativeWebView) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify({ type, payload }));
  }
};

export const isMobile = () => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  // const userAgent = navigator.userAgent;
  // if (userAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || userAgent.match(/LG|SAMSUNG|Samsung/) != null) {
  //     return true;
  // } else {
  //     return false;
  // }
};

export const getColorBySeverityCd = (code: number) => {
  switch (code) {
    case 200201:
      return '#E875BE';

    case 200202:
      return '#FEAA76';

    case 200203:
      return '#73C34E';

    case 200204:
      return '#5895F8';

    default:
      break;
  }
};

export const getStageAbbr = (val: string) => {
  switch (val) {
    case '수주완료':
      return '수주';
    case '영업기회발견':
      return '발견';
    case '제품소개,고객요건협의':
      return '소개';
    case '경쟁입찰/투찰':
      return '입찰';
    case '우선협상대상 선정':
      return '선정';
    case '가격협상':
      return '협상';
    default:
      return '';
  }
};
