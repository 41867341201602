import { ApiResponse } from 'apisauce';
import { IPaginationSnapshot } from 'src/models/pagination/Pagination';
import { IResponseSnapshot } from 'src/models/response/Response';

import { IMng, IMngFilter, IMngSnapshot } from '../../models';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';

import {
  TDeleteMngResult,
  TGetMngResult,
  TGetMngsResult,
  TPostMngResult,
  TUpdateMngResult,
} from './MngTypes';

/**
 * # Mng Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new MngApi();
 *
 * ```
 */
export class MngApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async gets(paging: IPaginationSnapshot, params?: IMngFilter): Promise<TGetMngsResult> {
    try {
      const url = `/v1/mng?page=${paging.page}&size=${paging.size}`;
      // const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const mngs: IMng[] = response.data.data;
      const pagination: IPaginationSnapshot = response.data.pagination;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', mngs, pagination, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(id: number): Promise<TGetMngResult> {
    try {
      const url = `/v1/mng/${id}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const mng: IMng = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', mng, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async post(mngData: IMngSnapshot, file?: File): Promise<TPostMngResult> {
    try {
      const url = '/v1/mng';
      const formData = new FormData();
      file && formData.append('file', file);
      const { mngSid, ...restData } = mngData; // 등록 시 reportSid 제거
      for (const key in restData) {
        // @ts-ignore
        formData.append(key, restData[key]);
      }
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, formData, config);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const mng: IMng = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', mng, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(mngSid: number, mngData: IMngSnapshot, file?: File): Promise<TUpdateMngResult> {
    try {
      const url = `/v1/mng/${mngSid}`;

      const formData = new FormData();
      file && formData.append('file', file);
      for (const key in mngData) {
        // @ts-ignore
        formData.append(key, mngData[key]);
      }
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      const response: ApiResponse<any> = await this.api.apisauce.put(url, formData, config);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const mng: IMng = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', mng, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(id: number): Promise<TDeleteMngResult> {
    try {
      const url = `/v1/mng/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async deleteImg(id: number): Promise<TDeleteMngResult> {
    try {
      const url = `/v1/mng/${id}/img`;
      const response: ApiResponse<any> = await this.api.apisauce.delete(url);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
