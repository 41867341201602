import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { createModelCode } from '../../model-code/ModelCode';

/**
 * # SpeedData
 *
 * SpeedData을 설명하세요.
 */
export const SpeedData = types
  .model('SpeedData')
  // --------------------------------------------------------------------------
  .props({
    name: createModelCode(),
    amt: types.maybeNull(types.optional(types.number, 0)),
    tooltip: types.maybeNull(types.array(types.string)),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model speedData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISpeedData;
      (Object.keys(newProps) as TSpeedDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSpeedData = Instance<typeof SpeedData>;
type TSpeedDataSnapshot = SnapshotOut<typeof SpeedData>;

export interface ISpeedData extends TSpeedData {}
export type TSpeedDataKeys = keyof TSpeedDataSnapshot & string;
export interface ISpeedDataSnapshot extends TSpeedDataSnapshot {}
export const createSpeedData = () => types.optional(SpeedData, {} as TSpeedData);
