import { Navigate, useRoutes } from 'react-router-dom';
import OsfcLayout from 'src/layouts/OsfcLayout';

// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import CompactLayout from '../layouts/compact';
// layouts
import SimpleLayout from '../layouts/simple';

//
import {
  Calendar,
  ComingSoon,
  Crm,
  Dashboard,
  Home,
  Login,
  Maintenance,
  Management,
  MyInfo,
  Page403,
  Page404,
  Page500,
  Project,
} from './elements';
import { PATH_ROOT } from './paths';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <OsfcLayout />
        </AuthGuard>
      ),
      children: [{ element: <Home />, index: true }],
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          path: '/', // PATH_AUTH.login,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    // Main Routes
    {
      element: (
        <AuthGuard>
          <OsfcLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Home />, index: true },
        {
          path: PATH_ROOT.fcChart.root,
          element: <Home />,
        },
        {
          path: PATH_ROOT.dashboard.root,
          element: <Dashboard />,
        },
        {
          path: PATH_ROOT.project.root,
          element: <Project />,
          // element: <YearGoalDialog />,
        },
        {
          path: PATH_ROOT.calendar.root,
          element: <Calendar />,
        },
        {
          path: PATH_ROOT.crm.root,
          element: <Crm />,
        },
        {
          path: PATH_ROOT.myInfo.root,
          element: <MyInfo />,
        },
        {
          path: PATH_ROOT.management.root,
          // element: <Management type={'user'} />,
          children: [
            { path: 'user', element: <Management type={'user'} />, index: true },
            { path: 'permission', element: <Management type={'permission'} /> },
            { path: 'group', element: <Management type={'group'} /> },
            { path: 'menu', element: <Management type={'menu'} /> },
            { path: 'setting', element: <Management type={'setting'} /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '403', element: <Page403 /> },
        { path: '404', element: <Page404 /> },
        { path: '500', element: <Page500 /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
