import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { ChartData } from '../chart-data/ChartData';

/**
 * # RowData
 *
 * RowData을 설명하세요.
 */
export const RowData = types
  .model('RowData')
  // --------------------------------------------------------------------------
  .props({
    summary: types.maybeNull(types.optional(types.array(types.maybeNull(ChartData)), [])), // 1월~분기~Total 합계
    preYearDiff: types.maybeNull(types.optional(types.array(types.maybeNull(ChartData)), [])), // 올해 vs 전년도 차이
    preMonthGap: types.maybeNull(types.optional(types.array(types.maybeNull(ChartData)), [])), // 전달 차액
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model rowData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRowData;
      (Object.keys(newProps) as TRowDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRowData = Instance<typeof RowData>;
type TRowDataSnapshot = SnapshotOut<typeof RowData>;

export interface IRowData extends TRowData {}
export type TRowDataKeys = keyof TRowDataSnapshot & string;
export interface IRowDataSnapshot extends TRowDataSnapshot {}
export const createRowData = () => types.optional(RowData, {} as TRowData);
