import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import {
  TDeleteYearGoalResult,
  TGetYearGoalResult,
  TSaveYearGoalResult,
  YearGoalApi,
} from '../../services';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { createYearGoal, IYearGoal, IYearGoalSnapshot, YearGoal } from '../year-goal/YearGoal';

/**
 * # YearGoalStore
 *
 * YearGoalStore을 설명하세요.
 */
export const YearGoalStore = types
  .model('YearGoalStore')
  // --------------------------------------------------------------------------
  .props({
    yearGoal: types.optional(YearGoal, {}),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setYearGoal: (res: IYearGoalSnapshot) => {
      self.yearGoal = res as IYearGoal;
    },
    resetYearGoal: () => {
      self.yearGoal = {} as IYearGoal;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    get: async (year: number) => {
      const yearGoalApi: YearGoalApi = new YearGoalApi(self.environment.api);
      const result: TGetYearGoalResult = await yearGoalApi.get(year);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setYearGoal(result.yearGoal);
      } else {
        console.error(result.kind);
      }
    },

    saveYearGoal: async (data: IYearGoalSnapshot) => {
      const yearGoalApi: YearGoalApi = new YearGoalApi(self.environment.api);
      const isEmpty = self.yearGoal.order.every((o) => o.amt === null || o.amt === undefined);
      const result: TSaveYearGoalResult = isEmpty
        ? await yearGoalApi.post(data)
        : await yearGoalApi.update(data);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        self.setYearGoal(result.yearGoal);
      } else {
        console.error(result.kind);
      }
    },

    delete: async (year: number) => {
      const yearGoalApi: YearGoalApi = new YearGoalApi(self.environment.api);
      const result: TDeleteYearGoalResult = await yearGoalApi.delete(year);
      self.rootStore.responseStore.getResponseResult(result);
      if (result.kind === 'ok') {
        return;
      } else {
        console.error(result.kind);
      }
    },
  }));

// --------------------------------------------------------------------------
type TYearGoalStore = Instance<typeof YearGoalStore>;
type TYearGoalStoreSnapshot = SnapshotOut<typeof YearGoalStore>;

export interface IYearGoalStore extends TYearGoalStore {}
export type TYearGoalStoreKeys = keyof TYearGoalStoreSnapshot & string;
export interface IYearGoalStoreSnapshot extends TYearGoalStoreSnapshot {}
